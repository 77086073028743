var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone-action",style:(_vm.phoneStyle)},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-bg1"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/bg1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-bg2"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/bg2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-bg3"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/bg3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-f1"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/f1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-f2"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/f2.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-f3"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/f3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-f4"},[_c('img',{attrs:{"draggable":"false","src":require("@/assets/images/action2/f4.png")}})])
}]

export { render, staticRenderFns }