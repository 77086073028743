const ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'
// const ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz'
var ALPHABET_MAP = {}
for (var i = 0; i < ALPHABET.length; i++) {
  ALPHABET_MAP[ALPHABET.charAt(i)] = i
}
var BASE = 58

function encode (buffer) {
  if (buffer.length === 0) return ''

  var i
  var j
  var digits = [0]
  for (i = 0; i < buffer.length; i++) {
    for (j = 0; j < digits.length; j++) digits[j] <<= 8

    digits[0] += buffer[i]

    var carry = 0
    for (j = 0; j < digits.length; ++j) {
      digits[j] += carry

      carry = (digits[j] / BASE) | 0
      digits[j] %= BASE
    }

    while (carry) {
      digits.push(carry % BASE)

      carry = (carry / BASE) | 0
    }
  }

  // deal with leading zeros
  for (i = 0; buffer[i] === 0 && i < buffer.length - 1; i++) digits.push(0)

  return digits.reverse().map(function (digit) { return ALPHABET[digit] }).join('')
}

function decode (string) {
  if (string.length === 0) return []

  var i
  var j
  var bytes = [0]
  for (i = 0; i < string.length; i++) {
    var c = string[i]
    if (!(c in ALPHABET_MAP)) throw new Error('Non-base58 character')

    for (j = 0; j < bytes.length; j++) bytes[j] *= BASE
    bytes[0] += ALPHABET_MAP[c]

    var carry = 0
    for (j = 0; j < bytes.length; ++j) {
      bytes[j] += carry

      carry = bytes[j] >> 8
      bytes[j] &= 0xff
    }

    while (carry) {
      bytes.push(carry & 0xff)

      carry >>= 8
    }
  }

  // deal with leading zeros
  for (i = 0; string[i] === '1' && i < string.length - 1; i++) bytes.push(0)

  // return bytes.reverse()
  // return buffer.from(bytes.reverse().toString('hex'), 'hex')
  var res = ''
  try {
    res = byteToString(bytes.reverse())
  } catch (e) {

  }
  return res
}
function byteToString (arr) {
  if (typeof arr === 'string') {
    return arr
  }
  var str = ''
  var _arr = arr
  for (var i = 0; i < _arr.length; i++) {
    // 数组中每个数字转为二进制 匹配出开头为1的直到0的字符
    // eg:123–>”1111011″–>{0:”1111″,groups: undefined, index: 0, input: “1111011”}
    var one = _arr[i].toString(2)
    var v = one.match(/^1+?(?=0)/)
    if (v && one.length === 8) {
      var bytesLength = v[0].length
      var store = _arr[i].toString(2).slice(7 - bytesLength)
      for (var st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2)
      }
      str += String.fromCharCode(parseInt(store, 2))
      i += bytesLength - 1
    } else {
      str += String.fromCharCode(_arr[i])
    }
  }
  return str
}
var base58 = {
  decode: decode, encode: encode
}
export default base58
