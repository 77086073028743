<template>
  <div id="app" :class="'theme-' + themeType " >
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted () {
    const href = window.location.href
    if (href.indexOf('unsubscribe?email') !== -1 && window.btoa) {
      const hl = href.split('?')
      window.location.href = hl[0] + '?' + window.btoa(hl[1])
    }
    // function isPC () {
    //   var userAgentInfo = navigator.userAgent
    //   var mobileAgents = ['Android', 'iPhone', 'iPad', 'iPod', 'Windows Phone', 'Mobile', 'BlackBerry', 'Opera Mini', 'IEMobile', 'WPDesktop']
    //   for (var i = 0; i < mobileAgents.length; i++) {
    //     if (userAgentInfo.indexOf(mobileAgents[i]) > -1) {
    //       return false
    //     }
    //   }
    //   return true
    // }
    // if (isPC()) {
    //   const href = window.location.href
    //   if (href.indexOf('?') !== -1) {
    //     const hl = href.split('?')
    //     localStorage.hrefParameter = hl[1]
    //     window.location.href = hl[0]
    //   }
    // }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  color: #000;
  &.theme-black{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111320;
  }
}
</style>
