<template>
  <div class="store-view home">
    <StorePage />
  </div>
</template>

<script>
// @ is an alias to /src
import StorePage from '@/components/StorePage.vue'

export default {
  name: 'StoreView',
  components: {
    StorePage
  }
}
</script>
