import axios from 'axios'
const baseURL = 'https://api.talkin.buzz'
window.axiosCancel = []
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  baseURL: baseURL + '/api',
  // 超时
  timeout: 500000
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Access-Control-Allow-Origin'] = '*'
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  console.log(res)
  return res.data
},
error => {
  // return Promise.reject(error)
  return error
})

export default service
