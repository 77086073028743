<template>
  <div class="contant-us home">
    <ContantUsPage />
  </div>
</template>

<script>
// @ is an alias to /src
import ContantUsPage from '@/components/ContantUsPage.vue'

export default {
  name: 'ContantUsView',
  components: {
    ContantUsPage
  }
}
</script>
