<template>
    <div class="colors-page">
        <div class="page-green isShow">
            <div class="p-left">
                <img draggable="false" class="img-hands" src="@/assets/images/colorful/Clapping hands.png" />
                <img draggable="false" class="img-g4" src="@/assets/images/colorful/g4.png" />
                <img draggable="false" class="img-g3" src="@/assets/images/colorful/g3.png" />
                <img draggable="false" class="img-g2" src="@/assets/images/colorful/g2.png" />
                <img draggable="false" class="img-g1" src="@/assets/images/colorful/g1.png" />
            </div>
            <div class="p-right">
                <h2 class="p-title">{{getTranFromKey('free_trans')}}</h2>
                <h3 class="p-desc">{{getTranFromKey('free_trans_text')}}</h3>
            </div>
        </div>
        <div class="page-purple isShow">
            <div class="p-bg">
                <img draggable="false" class="img" src="@/assets/images/colorful/p_bg.png" />
            </div>
            <div class="p-face">
                <span class="point"></span>
                <span class="block"></span>
                <span class="point"></span>
            </div>
            <div class="p-left">
                <h2 class="p-title">{{getTranFromKey('global_gift')}}</h2>
                <h3 class="p-desc">{{getTranFromKey('global_gift_text')}}</h3>
            </div>
            <div class="p-right">
                <div class="img-bg"></div>
                <!-- <img draggable="false" class="img" src="@/assets/images/colorful/p1.png" /> -->
                <img draggable="false" class="img2" src="@/assets/images/colorful/p2.png" />
                <img draggable="false" class="img3" src="@/assets/images/colorful/p3.png" />
            </div>
        </div>
        <div class="page-yellow isShow">
            <div class="p-bg">
                <img draggable="false" class="img" src="@/assets/images/colorful/y_bg.png" />
            </div>
            <div class="p-face">
                <span class="point"></span>
                <span class="block"></span>
                <span class="point"></span>
            </div>
            <div class="p-left">
                <img draggable="false" class="img2" src="@/assets/images/colorful/y2.png" />
                <img draggable="false" class="img3" src="@/assets/images/colorful/y3.png" />
                <img draggable="false" class="img4" src="@/assets/images/colorful/y4.png" />
                <img draggable="false" class="img5" src="@/assets/images/colorful/y5.png" />
                <img draggable="false" class="img6" src="@/assets/images/colorful/y6.png" />

                <img draggable="false" class="img7" src="@/assets/images/colorful/h1.png" />
                <img draggable="false" class="img8" src="@/assets/images/colorful/h2.png" />
                <img draggable="false" class="img9" src="@/assets/images/colorful/h3.png" />

                <!-- <span class="p-txt">此图片用户已授权使用</span> -->
            </div>

            <div class="p-right">
                <h2 class="p-title">{{getTranFromKey('global_post')}}</h2>
                <h3 class="p-desc">{{getTranFromKey('global_post_text')}}</h3>
            </div>
        </div>
        <div class="page-pink isShow">
            <div class="p-bg">
                <img draggable="false" class="img" src="@/assets/images/colorful/pk_bg.png" />
            </div>
            <div class="p-face">
                <span class="point"></span>
                <span class="block"></span>
                <span class="point"></span>
            </div>
            <div class="p-left">
                <h2 class="p-title">{{getTranFromKey('Learn_lang_video')}}</h2>
                <h3 class="p-desc">{{getTranFromKey('Learn_lang_video_text')}}</h3>
            </div>
            <div class="p-right">
                <img draggable="false" class="img-bg" src="@/assets/images/colorful/pk_bg2.png" />
                <img draggable="false" class="img2" src="@/assets/images/colorful/pk2.png" />
                <img draggable="false" class="img3" src="@/assets/images/colorful/pk3.png" />
                <img draggable="false" class="img4" src="@/assets/images/colorful/pk4.png" />
            </div>
        </div>
        <div class="page-white isShow">
            <div class="page-black">
                <slogan-card />
            </div>
            <!-- <div class="p-bg">
                <img draggable="false" class="img" src="@/assets/images/colorful/w_bg.png" />
            </div>
            <div class="p-cont">
                <div class="p-logo">
                    <img draggable="false" class="img-logo" src="@/assets/images/logo.png" />
                    <img draggable="false" class="img-txt" src="@/assets/images/logo-txt.png" />
                </div>
                <div class="p-desc">
                    <p>{{getTranFromKey('slogan').split(',')[0]}},</p>
                    <p>{{getTranFromKey('slogan').split(',')[1]}}</p>
                </div>
                <div class="p-btns">
                    <div class="btn-img" @click="$TurnIosOrAndroid(1)">
                        <img draggable="false" class="img" src="@/assets/images/download-app.png" />
                        <div class="btn1"></div>
                    </div>
                    <div class="btn-img" @click="$TurnIosOrAndroid(0)">
                        <img draggable="false" class="img" src="@/assets/images/download-google.png" />
                        <div class="btn2"></div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import SloganCard from '../common/SloganCard.vue'
export default {
  components: {
    SloganCard
  }
}
</script>

<style lang="scss" scoped>
.colors-page{
    .page-green{
        height: 4.56rem;
        background: #77E176;
        display: flex;
        align-items: center;
        justify-content: center;
        .p-left{
            width: 2.52rem;
            position: relative;
            // transform: translateX(-300%) scale(0);
            transition: all .5s ease;
            &::after{
                content: '';
                position: absolute;
                width: 1.78rem;
                height: 3.855rem;
                background: linear-gradient(180deg, #58C156 55.76%, rgba(88, 193, 86, 0) 85.14%);
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 0;
                border-radius: .20rem;
                    margin-left: .035rem;
                    top: .22rem;
            }
            img{
                position: relative;
                z-index: 1;
            }
            .img-hands{
                position: absolute;
                z-index: 10;
                right: -.80rem;
                bottom: .09rem;
                width: 1.06rem;
                animation:mymove5 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove5
            {
                0% {
                    transform: rotate(0) scale(0)
                }
                40% {
                    transform: rotate(0) scale(0)
                }
                45% {
                    transform: rotate(0) scale(1)
                }
                50% {
                    transform: rotate(-10deg) scale(1.1)
                }
                55% {
                    transform: rotate(10deg) scale(1.1)
                }
                60% {
                    transform: rotate(0) scale(1)
                }
                90% {
                    transform: rotate(0) scale(1)
                }
                95% {
                    transform: rotate(0) scale(1)
                }
                100% {
                    transform: rotate(0) scale(1)
                }
            }
            .img-g4{
                right: -.45rem;
                width: 1.885rem;
                animation:mymove4 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove4
            {
                0% {
                    transform: scale(0)
                }
                30% {
                    transform: scale(0)
                }
                40% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img-g3{
                width: 2.52rem;
                margin-top: .19rem;
                animation:mymove3 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove3
            {
                0% {
                    transform: scale(0)
                }
                20% {
                    transform: scale(0)
                }
                30% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img-g2{
                margin-top: .11rem;
                width: 2.26rem;
                animation:mymove2 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove2
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(0)
                }
                20% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img-g1{
                right: -.67rem;
                margin-top: .19rem;
                width: 1.64rem;

                animation:mymove1 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove1
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
        }
        .p-right{
            margin-left: 1.32rem;
            width: 3.00rem;
            text-align: left;
            // transform: translateX(300%) scale(0);
            transition: all .5s ease;
            .p-title{
                color: #224619;
                font-family: "SF Pro Text";
                font-size: .32rem;
                font-style: normal;
                font-weight: 800;
                line-height: .36rem; /* 112.5% */
                letter-spacing: -.0128rem;
                text-transform: capitalize;
                margin-bottom: .16rem;
            }
            .p-desc{
                color: #224619;
                font-family: "SF Pro Text";
                font-size: .13rem;
                font-style: normal;
                font-weight: 400;
                line-height: .16rem; /* 133.333% */
            }
        }
    }
    .page-purple{
        background: #6B45BB;
        position: relative;
        z-index: 10;
        height: 4.56rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .p-bg{
            position: absolute;
            z-index: 10;
            width: 100%;
            top: -.46rem;
            height: 1.18rem;
            left: -12%;
            .img{
                height: 100%;
            }
        }
        .p-left{
            position: relative;
            z-index: 11;
            width: 3.00rem;
            margin-right: .54rem;
            text-align: left;
            // transform: translate(0) scale(0);
            // transition: all .5s ease;
            .p-title{
                color: #F2E1FE;
                font-family: "SF Pro Text";
                font-size: .32rem;
                font-style: normal;
                font-weight: 800;
                line-height: .36rem; /* 112.5% */
                letter-spacing: -.0128rem;
                text-transform: capitalize;
                margin-bottom: .16rem;
            }
            .p-desc{
                color: #F2E1FE;
                font-family: "SF Pro Text";
                font-size: .13rem;
                font-style: normal;
                font-weight: 400;
                line-height: .16rem; /* 133.333% */
            }
        }
        .p-right{
            // transform: translate(0) scale(0);
            // transition: all .5s ease;

            // width: 3.60rem;
            width: 3.78rem;
            position: relative;
            .img2{
                // width: 1.91rem;
                // height: 1.91rem;
                // margin-left: -.955rem;
                // margin-top: -.955rem;
                position: absolute;
                top: 50%;
                left: 53%;
                // width: 2.8rem;
                // height: 2.607rem;
                // margin-left: -1.4rem;
                // margin-top: -1rem;
                // width: 3.4rem;
                // height: 3.4rem;
                // margin-left: -1.7rem;
                // margin-top: -1.5rem;
                width: 3.2rem;
                height: 3.2rem;
                margin-left: -1.6rem;
                margin-top: -1.35rem;
                z-index: 20;
                // animation:mymove11 3s;
            }
            @keyframes mymove11
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(0)
                }
                25% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img3{
                width: 100%;
                position: relative;
                z-index: 10;
                animation:mymove10 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove10
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img-bg{
                position: absolute;
                height: 3.85rem;
                width: 1.78rem;
                border-top-left-radius: .20rem;
                border-top-right-radius: .20rem;
                left: 53%;
                bottom: -.6rem;
                margin-left: -.89rem;
                background: linear-gradient(180deg, #5632A2 55.76%, rgba(87, 40, 141, 0.00) 85.14%, #6B45BB 85.14%);
                z-index: 1;
            }
        }
        .p-face{
            position: absolute;
            right: 1.00rem;
            top: .80rem;
            .point{
                width: .48rem;
                height: .72rem;
                display: inline-block;
                border-radius: .53rem;
                background: #8965D8;
            }
            .block{
                width: .48rem;
                display: inline-block;
            }
        }
    }
    .page-yellow{
        background: #F9D92C;
        position: relative;
        z-index: 10;
        height: 4.56rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .p-bg{
            position: absolute;
            z-index: 10;
            width: 100%;
            top: -.46rem;
            height: 1.18rem;
            right: -12%;
            .img{
                height: 100%;
            }
        }
        .p-face{
            position: absolute;
            left: 1.00rem;
            top: .80rem;
            .point{
                width: .48rem;
                height: .72rem;
                display: inline-block;
                border-radius: .08rem;
                background: #F3C826;
            }
            .block{
                width: .48rem;
                display: inline-block;
            }
        }
        .p-left{
            // padding-top: .96rem;
            height: 4.56rem;
            // transform: translateX(-300%) scale(0);
            // transition: all .5s ease;

            width: 3.6rem;
            padding-top: .85rem;
            box-sizing: border-box;
            position: relative;
            .img2{
                width: 1.78rem;
                position: relative;
                z-index: 10;
            }
            .img3{
                position: absolute;
                height: .44rem;
                left: .21rem;
                top: 2.63rem;
                z-index: 1;
            }
            .img4{
                position: absolute;
                height: .44rem;
                right: .43rem;
                z-index: 15;
                top: 2.62rem;
                animation:mymove34 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove34
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img5{
                position: absolute;
                height: .44rem;
                left: .07rem;
                top: 3.17rem;
                z-index: 16;
                animation:mymove35 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove35
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(0)
                }
                20% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img6{
                position: absolute;
                height: .44rem;
                top: 3.6rem;
                right: .13rem;
                z-index: 20;
                animation:mymove36 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove36
            {
                0% {
                    transform: scale(0)
                }
                20% {
                    transform: scale(0)
                }
                30% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img7{
                position: absolute;
                width: .28rem;
                top: .69rem;
                right: .87rem;
                z-index: 20;
                animation:mymove37 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove37
            {
                0% {
                    transform: scale(0)
                }
                30% {
                    transform: scale(0)
                }
                40% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img8{
                position: absolute;
                width: .32rem;
                right: .52rem;
                top: .91rem;
                animation:mymove38 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove38
            {
                0% {
                    transform: scale(0)
                }
                40% {
                    transform: scale(0)
                }
                50% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img9{
                position: absolute;
                width: .888rem;
                top: 1.23rem;
                right: .465rem;
                z-index: 20;
                animation:mymove39 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove39
            {
                0% {
                    transform: scale(0)
                }
                50% {
                    transform: scale(0)
                }
                60% {
                    transform: scale(1)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .p-txt{
                color: #DAA927;
                font-family: "SF Pro Text";
                font-size: .11rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                position: absolute;
                left: 50%;
                bottom: .27rem;
                transform: translateX(-50%);
            }
        }
        .p-right{
            position: relative;
            z-index: 11;
            width: 3.00rem;
            margin-left: .54rem;
            text-align: left;
            // transform: translateX(300%) scale(0);
            // transition: all .5s ease;
            .p-title{
                color: #4C4123;
                font-family: "SF Pro Text";
                font-size: .32rem;
                font-style: normal;
                font-weight: 800;
                line-height: .36rem; /* 112.5% */
                letter-spacing: -.0128rem;
                text-transform: capitalize;
                margin-bottom: .16rem;
            }
            .p-desc{
                color: #4C4123;
                font-family: "SF Pro Text";
                font-size: .13rem;
                font-style: normal;
                font-weight: 400;
                line-height: .16rem; /* 133.333% */
            }
        }
    }
    .page-pink{
        background: #FC5EA9;
        position: relative;
        z-index: 10;
        height: 4.56rem;;
        display: flex;
        align-items: center;
        justify-content: center;
        .p-bg{
            position: absolute;
            z-index: 10;
            width: 100%;
            top: -.46rem;
            height: 1.18rem;
            left: -12%;
            .img{
                height: 100%;
            }
        }
        .p-face{
            position: absolute;
            right: 1.00rem;
            top: .80rem;
            .point{
                width: .48rem;
                height: .48rem;
                display: inline-block;
                border-radius: .50rem;
                background: #FF8EC4;
                &:first-child{
                    position: relative;
                    top: .22rem;
                }
            }
            .block{
                width: .33rem;
                display: inline-block;
            }
        }
        .p-right{
            // padding-top: .96rem;
            height: 4.56rem;
            // transform: translate(0) scale(0);
            // transition: all .5s ease;

            width: 3.6rem;
            position: relative;
            padding-top: .85rem;
            box-sizing: border-box;
            .img-bg{
                width: 1.78rem;
            }
            .img2{
                position: absolute;
                width: .88rem;
                top: 1.26rem;
                left: .42rem;
                animation:mymove22 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove22
            {
                0% {
                    transform: scale(0)
                }
                20% {
                    transform: scale(0) rotate(24deg)
                }
                30% {
                    transform: scale(1) rotate(24deg)
                }
                40% {
                    transform: scale(1) rotate(0deg)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img3{
                width: 1.11rem;
                position: absolute;
                right: .35rem;
                top: .56rem;
                animation:mymove23 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove23
            {
                0% {
                    transform: scale(0)
                }
                10% {
                    transform: scale(0) rotate(-12deg)
                }
                20% {
                    transform: scale(1) rotate(-12deg)
                }
                30% {
                    transform: scale(1) rotate(0)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
            .img4{
                width: 2.23rem;
                height: 1.64rem;
                position: absolute;
                bottom: .5rem;
                left: 50%;
                margin-left: -1.115rem;
                animation:mymove24 3s;
                animation-delay: .05s;
                transform: scale(0);
                animation-fill-mode: forwards;
            }
            @keyframes mymove24
            {
                0% {
                    transform: scale(0) rotate(3deg)
                }
                10% {
                    transform: scale(1) rotate(3deg)
                }
                20% {
                    transform: scale(1) rotate(0)
                }
                90% {
                    transform: scale(1)
                }
                100% {
                    transform: scale(1)
                }
            }
        }
        .p-left{
            position: relative;
            z-index: 11;
            width: 3.00rem;
            margin-right: .54rem;
            text-align: left;
            // transform: translate(0) scale(0);
            // transition: all .5s ease;
            .p-title{
                color: #FFF;
                font-family: "SF Pro Text";
                font-size: .32rem;
                font-style: normal;
                font-weight: 800;
                line-height: .36rem; /* 112.5% */
                letter-spacing: -.0128rem;
                text-transform: capitalize;
                margin-bottom: .16rem;
            }
            .p-desc{
                color: #FFF;
                font-family: "SF Pro Text";
                font-size: .13rem;
                font-style: normal;
                font-weight: 400;
                line-height: .16rem; /* 133.333% */
            }
        }
    }
    .isShow{
        // .p-left, .p-right{
        //     transform: translate(0) scale(1);
        // }
        img {
            animation: none !important;
        }
    }
    .page-white{
        background: #F1F3FF;
        position: relative;
        z-index: 10;
        height: 4.20rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .page-black{
            background: #111320;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .p-bg{
            position: absolute;
            z-index: 10;
            width: 100%;
            top: -.46rem;
            height: 1.18rem;
            right: -12%;
            .img{
                height: 100%;
            }
        }
        &.isShow .p-cont{
            transform: translateY(300px) scale(0);
            opacity: 0;
        }
        .p-cont{
            transform: translateY(0) scale(1);
            transition: all .5s ease;
            opacity: 1;
            .p-logo{
                display: flex;
                align-items: center;
                margin-bottom: .16rem;
                  justify-content: center;
                .img-logo{
                    width: .60rem;
                }
                .img-txt{
                    width: 1.06rem;
                    margin: 0 .12rem;
                }
            }
            .p-desc{
                color: #27272E;
                text-align: center;
                font-family: "SF Pro Text";
                font-size: .28rem;
                font-style: normal;
                font-weight: 800;
                line-height: .32rem; /* 114.286% */
                letter-spacing: -.01rem;
                text-transform: capitalize;
            }
            .p-btns{
                color: #27272E;
                font-family: "SF Pro Text";
                font-size: .20rem;
                font-style: normal;
                font-weight: 800;
                line-height: .48rem;
                letter-spacing: -.01rem;
                text-transform: capitalize;
                height: 1.16rem;
                position: relative;
                width: 4.00rem;
                .btn-img{
                    width: 2.10rem;
                    position: relative;
                    .img{
                        width: 100%;
                    }
                    &:last-child{
                        position: absolute;
                        top: 0;
                        left: 1.70rem;
                        width: 2.32rem;
                    }
                }
                .btn1{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1.60rem;
                    height: .55rem;
                    transform: translate(-50%, -50%);
                    // background: rgba(255,255,255,.3);
                    margin-top: -4%;
                    z-index: 10;
                    cursor:pointer;
                }
                .btn2{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 1.74rem;
                    height: .55rem;
                    transform: translate(-50%, -50%);
                    // background: rgba(255, 255, 255, 0.3);
                    margin-top: -4%;
                    z-index: 10;
                    cursor:pointer;
                }
            }
        }
    }
}
</style>
