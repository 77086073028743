<template>
  <div class="unsubscribe-view page-home" :class="'theme-' + themeType + (isMobile ? ' mobile' : '')" >
    <fix-nav />
    <div class="s-contain" v-if="params">
      <div class="s-info" :class="{'is-unsubscribe': !isUnsubscribe}">
        <div class="s-card">
          <div class="s-email">
            <svg class="s-svg" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <path d="M1.25 14C1.25 21.0416 6.95837 26.75 14 26.75C21.0416 26.75 26.75 21.0416 26.75 14C26.75 6.95837 21.0416 1.25 14 1.25C6.95837 1.25 1.25 6.95837 1.25 14Z" fill="#FFE12B" stroke="white" stroke-width="2.5" stroke-linejoin="round"/>
              <rect x="20.6499" y="18" width="3" height="4" rx="1.5" transform="rotate(-180 20.6499 18)" fill="#37394B"/>
              <rect x="15" y="18" width="3" height="4" rx="1.5" transform="rotate(-180 15 18)" fill="#37394B"/>
            </svg>
            <span class="s-txt">{{params.email}}</span>
          </div>
          <div class="s-tit" v-if="isUnsubscribe">{{getTranFromKey('edm_unsubscribe')}}</div>
          <div class="s-tit" v-else>{{getTranFromKey('unsubscribed_successful')}}</div>
          <div class="s-desc">
            <span v-if="isUnsubscribe">
              {{getTranFromKey('unsubscribe_notice')}}
            </span>
            <span v-else>
              {{getTranFromKey('no_more_recommendations')}}
            </span>
          </div>
          <div class="s-btns" v-if="isUnsubscribe">
            <span class="btn-confirm" @click="handleConfirm">
              {{getTranFromKey('confirm')}}
            </span>
            <!-- <UnButton @handleBtn="handleConfirm" :isClick="isConfirmClick" /> -->
            <span class="btn-cancel" @click="handleCancel">{{getTranFromKey('cancel')}}</span>
          </div>
          <div class="s-contact" @click="handleContact">{{getTranFromKey('contact_for_question')}}</div>
        </div>
      </div>
      <div class="s-line">
        <svg xmlns="http://www.w3.org/2000/svg" width="1246" height="2" viewBox="0 0 1246 2" fill="none">
        <path d="M0 1H1246" stroke="#262839"/>
        </svg>
      </div>
        <div class="slogan-card">
            <slogan-card />
        </div>
    </div>
  </div>
</template>
<script>
import FixNav from './FixNav.vue'
import SloganCard from './common/SloganCard.vue'
// import UnButton from './common/UnButton.vue'

import { updateEmailSubscribeState } from '@/api/email'

import base58 from '@/utils/base58.js'

export default {
  name: 'UnsubscribePage',
  components: {
    FixNav,
    SloganCard
    // UnButton
  },
  data () {
    return {
      isMobile: false,
      isUnsubscribe: true,
      isConfirmClick: false,
      params: ''
    }
  },
  created () {
    var params = localStorage.hrefParameter || ''
    var sParam = ''
    const href = window.location.href
    if (href.indexOf('?') !== -1) {
      const hl = href.split('?')
      localStorage.hrefParameter = hl[1]
      params = hl[1]
      if (params.indexOf('email') === -1 && window.atob) {
        console.log(params)
        params = window.atob(decodeURIComponent(params))
      }
      sParam = this.getUrlParams('?' + params)
      // console.log(sParam)
      // sParam.email = window.atob(sParam.email)
      // console.log(sParam.email)
      sParam.email = base58.decode(sParam.email)
      this.params = sParam
      const data = localStorage.emailRecord
      if (data) {
        if (data.indexOf(sParam.email) !== -1) {
          this.isUnsubscribe = false
        }
      }
    } else if (params) {
      sParam = this.getUrlParams('?' + params)
      // console.log(sParam)
      // sParam.email = window.atob(sParam.email)
      // console.log(sParam.email)
      sParam.email = base58.decode(sParam.email)
      this.params = sParam
      const data = localStorage.emailRecord
      if (data) {
        if (data.indexOf(sParam.email) !== -1) {
          this.isUnsubscribe = false
        }
      }
    } else {
      window.location.href = '/#/'
    }
  },
  mounted () {
    if (document.getElementsByClassName('page-home')[0].clientWidth < 800) {
      this.isMobile = true
    }
  },
  methods: {
    handleContact () {
      window.location.href = '/#/'
    },
    getUrlParams (rurl) {
      var url = decodeURIComponent(rurl)
      var theRequest = {}
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1)
        if (str.indexOf('=') !== -1) {
          var strs = str.split('&')
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].replace(strs[i].split('=')[0] + '=', ''))
          }
        } else {
          theRequest.code = str
        }
      }
      return theRequest
    },
    handleConfirm () {
      if (this.isConfirmClick) {
        return
      }
      this.isConfirmClick = true
      this.updateEmailState()
      setTimeout(() => {
        this.isConfirmClick = false
        this.isUnsubscribe = false
        // /api/email/v1/subscribe-state
      }, 300)
      // this.isUnsubscribe = false
    },
    async updateEmailState () {
      const params = {
        aid: this.params.aid,
        email_id: this.params.email_id,
        sub_type: 'UNSUBSCRIBE_EMAIL' // 退订
      }
      const res = await updateEmailSubscribeState(params)
      if (res.code === '200') {
        var edata = localStorage.emailRecord || ''
        edata += this.params.email + ','
        localStorage.emailRecord = edata
      }
      this.isUnsubscribe = false
      console.log(edata, res)
    },
    handleCancel () {
      window.location.href = window.location.origin
    }
  }
}
</script>
<style lang="scss" scoped>
.unsubscribe-view{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  overflow-y: auto;
  overflow-x: hidden;
  &.theme-black{
    background: #111320;
  }
  &.mobile{
    .s-contain .s-info{
      height: auto;
      padding: .1rem;
    }
  }
}
.unsubscribe-view .s-contain {
  padding-top: 0.6rem;
  max-width: 9.42rem;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  .s-info{
    height: calc(100% - 4.10rem);
    min-height: 3.78rem;
    display: flex;
    align-items: center;
    // &.is-unsubscribe{
    //   min-height: 1.9rem;
    //   height: calc(100% - 4.5rem);
    // }
  }
  .s-card{
    width: 4.48rem;
    margin: 0 auto;
    text-align: left;
    padding: 0 .1rem;
    .s-email{
      .s-svg{
        width: .28rem;
        height: .28rem;
        flex-shrink: 0;
        margin-right: .08rem;
        vertical-align: middle;
      }
      .s-txt{
        color: #F1F3FE;
        font-family: "SF Pro Text";
        font-size: .14rem;
        font-style: normal;
        font-weight: 400;
        line-height: .24rem; /* 171.429% */
      }
    }
    .s-tit{
      padding: .4rem 0 .12px 0;

      color: #F1F3FE;
      font-family: "SF Pro Text";
      font-size: .28rem;
      font-style: normal;
      font-weight: 700;
      line-height: .42rem; /* 150% */
    }
    .s-desc{
      color:  #F1F3FE;
      font-size: .15rem;
      font-style: normal;
      font-weight: 400;
      line-height: .22rem;
      padding-bottom: .4rem;
    }
    .s-btns{
        color:  #F1F3FE;
        text-align: center;
        font-family: "SF Pro Text";
        font-size: .16rem;
        font-style: normal;
        font-weight: 700;
        line-height: .24rem; /* 150% */
        text-transform: capitalize;

        padding-bottom: .4rem;
      .btn-confirm{
        display: flex;
        width: 100%;
        // min-width: 2.6rem;
        padding: .12rem .16rem;
        justify-content: center;
        align-items: center;

        border-radius: .27rem;
        background: #A56DFF;

        margin-bottom: .12rem;

        cursor: pointer;
        box-sizing: border-box;
      }
      .btn-cancel{
        display: flex;
        width: 100%;
        // max-width: 2.6rem;
        padding: .12rem .16rem;
        justify-content: center;
        align-items: center;
        border-radius: .27rem;
        background:  #37394B;

        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .s-contact{
      color:  #7D7E8B;
      font-family: "SF Pro Text";
      font-size: .14rem;
      font-style: normal;
      font-weight: 400;
      line-height: .24rem;
      text-decoration-line: underline;
      text-transform: capitalize;

      // margin-bottom: .8rem;
      cursor: pointer;
    }
  }
  .slogan-card{
    // height: 410px;
    height: 2.94rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .s-line{
    // width:86.528%;
    height: 0px;
    svg{
      width: 100%;
      vertical-align: text-top;
    }
  }
}

</style>
<style>
.home .mobile.unsubscribe-view .p-slogan .p-btns .btn-img{
    width: 2.32rem;
    margin: 0 auto;
}
.home .mobile.unsubscribe-view .p-slogan .p-btns .btn-img:last-child{
    position: relative;
    left: 0;
    margin: 0 auto;
}
</style>
