<template>
  <div class="unsubscribe home">
    <UnsubscribePage />
  </div>
</template>

<script>
// @ is an alias to /src
import UnsubscribePage from '@/components/UnsubscribePage.vue'

export default {
  name: 'UnsubscribeView',
  components: {
    UnsubscribePage
  }
}
</script>
