const maxSize = 300;
// let fontSize = document.documentElement.clientWidth / 8.05;
let fontSize = document.documentElement.clientWidth / 19.2;
// let fontSize = 2550 / 19.2;

fontSize > maxSize && (fontSize = maxSize)
if (fontSize < 100) {
    fontSize = 100
}
// 移动端 fongSize设置 375款
document.documentElement.style.fontSize = fontSize + "px";

Date.prototype.Format = function(fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}
export function formatTimeToStr(times, pattern) {
  var d = new Date(times).Format('yyyy-MM-dd hh:mm:ss')
  if (pattern) {
    d = new Date(times).Format(pattern)
  }
  return d.toLocaleString()
}
// 查看是不是同一天
const checkIsSameDay = () => {
  const dayTime = localStorage.dayTime || new Date().getTime()
  const nowTime = new Date().getTime()
  return formatTimeToStr(parseInt(dayTime), 'yyyy-MM-dd') === formatTimeToStr(nowTime, 'yyyy-MM-dd')
}
import Vue from 'vue'
    
    // 获取默认语言
    class navigatorLanguage {
        constructor() {
            this.lang = this.getLang()
            // console.log(this.lang, 'test')
        }
        getLang() {
            var bLang = navigator.language
            ? navigator.language
            : navigator.browserLanguage;
            if (bLang) {
                bLang = bLang.replace('-', '_').toLowerCase()
            } else {
                return
            }
            var lang = bLang || 'en' // 都拿不到默认为 英

            if(bLang === 'zh' || bLang.indexOf('zh_') !== -1){ // 中
                if(bLang === 'zh_tw' || bLang === 'zh_hk') {
                    lang = 'zh-Hant'
                }else {
                    lang = 'zh-Hans'
                }
            }
            lang = lang.replace(/[_][\s\S]*/, '')
            const langList = [
                "zh-Hans","zh-Hant","en","ja","ko",
                "es", "fr","de","it",
                "pt","ru","tr",
                "ar","id","vi","th",
                "hi","pl","nl","sv"
            ]
            if(langList.indexOf(lang) === -1){
                lang = 'en'
            }
            // if (localStorage.dayTime && checkIsSameDay()) {
              lang = sessionStorage.lang || lang
            // }
            if(lang === 'ar') {
                // setTimeout(() => {
                    // document.documentElement.setAttribute("dir", "rtl")
                    // document.getElementById('app').className = 'dir-rtl'
                    // document.getElementsByClassName('chat-title')[0].setAttribute("dir", "rtl")

                // }, 100)
            }
            return lang
        }
    }
// 翻译
class tranTxtLang {
    constructor(lang) {
        this.lang = lang
        this.jsonData = {
                "slogan": {
                  "zh-Hans": "世界无界,聊在TalkIn",
                  "zh-Hant": "世界無界,聊在TalkIn",
                  "en": "Beyond Borders, Talk In TalkIn.",
                  "ja": "Beyond Borders, Talk In TalkIn.",
                  "ko": "Beyond Borders, Talk In TalkIn.",
                  "es": "Beyond Borders, Talk In TalkIn.",
                  "fr": "Beyond Borders, Talk In TalkIn.",
                  "de": "Beyond Borders, Talk In TalkIn.",
                  "it": "Beyond Borders, Talk In TalkIn.",
                  "pt": "Beyond Borders, Talk In TalkIn.",
                  "ru": "Beyond Borders, Talk In TalkIn.",
                  "tr": "Beyond Borders, Talk In TalkIn.",
                  "ar": "Beyond Borders, Talk In TalkIn.",
                  "id": "Beyond Borders, Talk In TalkIn.",
                  "vi": "Beyond Borders, Talk In TalkIn.",
                  "th": "Beyond Borders, Talk In TalkIn.",
                  "hi": "Beyond Borders, Talk In TalkIn.",
                  "pl": "Beyond Borders, Talk In TalkIn.",
                  "nl": "Beyond Borders, Talk In TalkIn.",
                  "sv": "Beyond Borders, Talk In TalkIn."
                },
                "match_text": {
                  "zh-Hans": "已经发生了2,767,837次语伴匹配",
                  "zh-Hant": "已經發生了2,767,837次語伴匹配",
                  "en": "2,767,837 language partner matches have already occurred",
                  "ja": "すでに2,767,837回の言語パートナーマッチングが行われています",
                  "ko": "이미 2,767,837회의 언어 파트너 매칭이 이루어졌습니다",
                  "es": "Ya se han producido 2,767,837 emparejamientos de compañeros de idiomas",
                  "fr": "2,767,837 correspondances de partenaires linguistiques ont déjà eu lieu",
                  "de": "Es haben bereits 2,767,837 Sprachpartner-Übereinstimmungen stattgefunden",
                  "it": "Si sono già verificati 2,767,837 abbinamenti di compagni di lingua",
                  "pt": "Já ocorreram 2,767,837 combinações de parceiros de idiomas",
                  "ru": "Уже произошло 2,767,837 совпадений языковых партнеров",
                  "tr": "2,767,837 dil partneri eşleşmesi zaten gerçekleşti",
                  "ar": "لقد حدث بالفعل 2,767,837 مطابقة لشركاء اللغة",
                  "id": "Sudah terjadi 2,767,837 kali kecocokan pasangan bahasa",
                  "vi": "Đã xảy ra 2,767,837 lần ghép đôi người học ngôn ngữ",
                  "th": "มีการจับคู่พาร์ทเนอร์ภาษาแล้ว 2,767,837 ครั้ง",
                  "hi": "2,767,837 बार भाषा साथी मिलान पहले ही हो चुका है",
                  "pl": "Już miało miejsce 2,767,837 dopasowań partnerów językowych",
                  "nl": "Er hebben al 2,767,837 taalpartnermatches plaatsgevonden",
                  "sv": "Det har redan skett 2,767,837 språkpartnermatchningar"
                },
                "free_trans": {
                  "zh-Hans": "翻译永久免费",
                  "zh-Hant": "翻譯永久免費",
                  "en": "Translation is always free",
                  "ja": "翻訳は永久に無料",
                  "ko": "번역은 항상 무료입니다",
                  "es": "La traducción siempre es gratuita",
                  "fr": "La traduction est toujours gratuite",
                  "de": "Übersetzung ist immer kostenlos",
                  "it": "La traduzione è sempre gratuita",
                  "pt": "A tradução é sempre gratuita",
                  "ru": "Перевод всегда бесплатен",
                  "tr": "Çeviri her zaman ücretsizdir",
                  "ar": "الترجمة دائمًا مجانية",
                  "id": "Terjemahan selalu gratis",
                  "vi": "Dịch vụ dịch luôn miễn phí",
                  "th": "การแปลฟรีตลอดไป",
                  "hi": "अनुवाद हमेशा मुफ्त होता है",
                  "pl": "Tłumaczenie jest zawsze darmowe",
                  "nl": "Vertaling is altijd gratis",
                  "sv": "Översättning är alltid gratis"
                },
                "free_trans_text": {
                  "zh-Hans": "TalkIn不限次数的全模块终身免费翻译服务，确保全球用户能够无障碍沟通",
                  "zh-Hant": "TalkIn提供不限次數的全模塊終身免費翻譯服務，確保全球用戶能夠無障礙溝通。",
                  "en": "TalkIn offers unlimited full-module lifetime free translation services, ensuring barrier-free communication for global users.",
                  "ja": "TalkInは回数無制限の全モジュール終身無料翻訳サービスを提供し、世界中のユーザーが障害なくコミュニケーションできることを保証します。",
                  "ko": "TalkIn은 무제한 전 모듈 평생 무료 번역 서비스를 제공하여 전 세계 사용자가 장애 없이 소통할 수 있도록 보장합니다.",
                  "es": "TalkIn ofrece servicios de traducción gratuitos de por vida en todos los módulos y sin límite de uso, asegurando una comunicación sin barreras para usuarios globales.",
                  "fr": "TalkIn offre un service de traduction gratuit à vie pour tous les modules et sans limite d'utilisation, assurant une communication sans barrière pour les utilisateurs du monde entier.",
                  "de": "TalkIn bietet unbegrenzte lebenslange kostenlose Übersetzungsdienste für alle Module, um sicherzustellen, dass Nutzer weltweit barrierefrei kommunizieren können.",
                  "it": "TalkIn offre servizi di traduzione gratuiti a vita per tutti i moduli senza limiti di utilizzo, garantendo una comunicazione senza barriere per gli utenti globali.",
                  "pt": "TalkIn oferece serviços de tradução gratuitos para toda a vida em todos os módulos e sem limites de uso, garantindo comunicação sem barreiras para usuários globais.",
                  "ru": "TalkIn предоставляет неограниченные бессрочные бесплатные услуги перевода всех модулей, обеспечивая беспрепятственное общение для пользователей по всему миру.",
                  "tr": "TalkIn, tüm modüllerde sınırsız ömür boyu ücretsiz çeviri hizmeti sunarak, küresel kullanıcıların engelsiz iletişim kurmasını sağlar.",
                  "ar": "يقدم TalkIn خدمات ترجمة مجانية مدى الحياة لجميع الوحدات دون قيود، مما يضمن التواصل دون عوائق للمستخدمين العالميين.",
                  "id": "TalkIn menawarkan layanan terjemahan gratis seumur hidup tanpa batas untuk semua modul, memastikan komunikasi tanpa hambatan bagi pengguna global.",
                  "vi": "TalkIn cung cấp dịch vụ dịch thuật miễn phí trọn đời không giới hạn cho tất cả các mô-đun, đảm bảo giao tiếp không rào cản cho người dùng toàn cầu.",
                  "th": "TalkIn ให้บริการแปลภาษาฟรีตลอดชีพไม่จำกัดจำนวนทุกโมดูล เพื่อให้ผู้ใช้ทั่วโลกสื่อสารได้โดยไม่มีอุปสรรค",
                  "hi": "TalkIn सभी मॉड्यूल में असीमित आजीवन मुफ्त अनुवाद सेवाएं प्रदान करता है, वैश्विक उपयोगकर्ताओं के लिए बाधारहित संचार सुनिश्चित करता है।",
                  "pl": "TalkIn oferuje nieograniczone, dożywotnie bezpłatne usługi tłumaczeniowe dla wszystkich modułów, zapewniając swobodną komunikację dla użytkowników na całym świecie.",
                  "nl": "TalkIn biedt onbeperkte levenslange gratis vertaaldiensten voor alle modules, waardoor wereldwijde gebruikers zonder barrières kunnen communiceren.",
                  "sv": "TalkIn erbjuder obegränsad livstids gratis översättningstjänst för alla moduler, vilket säkerställer barriärfri kommunikation för globala användare."
                },
                "global_gift": {
                  "zh-Hans": "全球专属礼物",
                  "zh-Hant": "全球專屬禮物",
                  "en": "Global Exclusive Gift",
                  "ja": "グローバル専用ギフト",
                  "ko": "글로벌 전용 선물",
                  "es": "Regalo Exclusivo Global",
                  "fr": "Cadeau Exclusif Mondial",
                  "de": "Globales Exklusivgeschenk",
                  "it": "Regalo Esclusivo Globale",
                  "pt": "Presente Exclusivo Global",
                  "ru": "Глобальный Эксклюзивный Подарок",
                  "tr": "Küresel Özel Hediye",
                  "ar": "هدية حصرية عالمية",
                  "id": "Hadiah Eksklusif Global",
                  "vi": "Quà Tặng Độc Quyền Toàn Cầu",
                  "th": "ของขวัญพิเศษระดับโลก",
                  "hi": "वैश्विक विशेष उपहार",
                  "pl": "Globalny Ekskluzywny Prezent",
                  "nl": "Wereldwijd Exclusief Geschenk",
                  "sv": "Global Exklusiv Gåva"
                },
                "global_gift_text": {
                  "zh-Hans": "TalkIn提供超过30个不同国家的专属特色礼物，用礼物分享文化，与全球用户交换属于各国的礼物，分享两国文化特色，让友谊超越国界，向世界展示你的文化。",
                  "zh-Hant": "TalkIn提供超過30個不同國家的專屬特色禮物，用禮物分享文化，與全球用戶交換屬於各國的禮物，分享兩國文化特色，讓友誼超越國界，向世界展示你的文化。",
                  "en": "TalkIn offers exclusive gifts from over 30 different countries, sharing culture through gifts, exchanging unique gifts with global users, showcasing cultural characteristics of both countries, transcending boundaries with friendship, and presenting your culture to the world.",
                  "ja": "TalkInは30カ国以上の国々からの独自の特徴的なギフトを提供し、ギフトを通じて文化を共有し、世界中のユーザーと各国の特有のギフトを交換し、両国の文化的特徴を共有し、友情を国境を超えて、あなたの文化を世界に示します。",
                  "ko": "TalkIn은 30개 이상의 다른 나라의 독특한 특징이 있는 선물을 제공하여 선물을 통해 문화를 공유하고, 전 세계 사용자와 각국의 특별한 선물을 교환하며, 두 나라의 문화적 특성을 공유하고, 우정으로 국경을 초월하여 당신의 문화를 세계에 보여줍니다.",
                  "es": "TalkIn ofrece regalos exclusivos de más de 30 países diferentes, compartiendo cultura a través de regalos, intercambiando regalos únicos con usuarios globales, mostrando las características culturales de ambos países, trascendiendo fronteras con la amistad y presentando tu cultura al mundo.",
                  "fr": "TalkIn propose des cadeaux exclusifs de plus de 30 pays différents, partageant la culture à travers des cadeaux, échangeant des cadeaux uniques avec des utilisateurs mondiaux, mettant en avant les caractéristiques culturelles des deux pays, transcendant les frontières avec l'amitié et présentant votre culture au monde.",
                  "de": "TalkIn bietet exklusive Geschenke aus über 30 verschiedenen Ländern an, teilt Kultur durch Geschenke, tauscht einzigartige Geschenke mit globalen Nutzern aus, zeigt die kulturellen Merkmale beider Länder, überwindet Grenzen mit Freundschaft und präsentiert deine Kultur der Welt.",
                  "it": "TalkIn offre regali esclusivi da oltre 30 paesi diversi, condividendo la cultura attraverso i regali, scambiando regali unici con utenti globali, mostrando le caratteristiche culturali di entrambi i paesi, superando i confini con l'amicizia e presentando la tua cultura al mondo.",
                  "pt": "TalkIn oferece presentes exclusivos de mais de 30 países diferentes, compartilhando cultura através de presentes, trocando presentes únicos com usuários globais, mostrando características culturais de ambos os países, transcendendo fronteiras com a amizade e apresentando sua cultura ao mundo.",
                  "ru": "TalkIn предлагает эксклюзивные подарки из более чем 30 разных стран, делится культурой через подарки, обменивается уникальными подарками с пользователями по всему миру, демонстрирует культурные особенности обеих стран, преодолевает границы дружбой и представляет вашу культуру миру.",
                  "tr": "TalkIn, 30'dan fazla farklı ülkeden özel hediyeler sunarak, hediyeler aracılığıyla kültür paylaşıyor, küresel kullanıcılarla benzersiz hediyeler değiş tokuş ediyor, her iki ülkenin kültürel özelliklerini sergileyerek dostlukla sınırları aşarak kültürünüzü dünyaya sunuyor.",
                  "ar": "يقدم TalkIn هدايا حصرية من أكثر من 30 دولة مختلفة، مشاركة الثقافة من خلال الهدايا، تبادل الهدايا الفريدة مع المستخدمين العالميين، عرض الخصائص الثقافية لكلا البلدين، تجاوز الحدود بالصداقة وعرض ثقافتك للعالم.",
                  "id": "TalkIn menawarkan hadiah eksklusif dari lebih dari 30 negara berbeda, berbagi budaya melalui hadiah, bertukar hadiah unik dengan pengguna global, menampilkan ciri khas budaya kedua negara, melampaui batas dengan persahabatan, dan memperkenalkan budaya Anda ke dunia.",
                  "vi": "TalkIn cung cấp quà tặng độc quyền từ hơn 30 quốc gia khác nhau, chia sẻ văn hóa qua quà tặng, trao đổi quà tặng độc đáo với người dùng toàn cầu, thể hiện đặc trưng văn hóa của cả hai quốc gia, vượt qua biên giới bằng tình bạn, và giới thiệu văn hóa của bạn cho thế giới.",
                  "th": "TalkIn นำเสนอของขวัญพิเศษจากมากกว่า 30 ประเทศที่แตกต่างกัน แบ่งปันวัฒนธรรมผ่านของขวัญ แลกเปลี่ยนของขวัญเฉพาะกับผู้ใช้ทั่วโลก แสดงลักษณะทางวัฒนธรรมของทั้งสองประเทศ ข้ามขีดจำกัดด้วยมิตรภาพ และนำเสนอวัฒนธรรมของคุณให้โลกได้รู้จัก",
                  "hi": "TalkIn 30 से अधिक अलग-अलग देशों के विशेष उपहार प्रदान करता है, उपहारों के माध्यम से संस्कृति को साझा करता है, वैश्विक उपयोगकर्ताओं के साथ प्रत्येक देश के विशेष उपहारों का आदान-प्रदान करता है, दोनों देशों की सांस्कृतिक विशेषताएं साझा करता है, मित्रता के माध्यम से सीमाओं को पार करता है, और अपनी संस्कृति को विश्व के सामने प्रस्तुत करता है।",
                  "pl": "TalkIn oferuje ponad 30 unikalnych prezentów z różnych krajów, pozwalając na dzielenie się kulturą poprzez prezenty. Wymieniaj się prezentami charakterystycznymi dla różnych krajów z użytkownikami z całego świata, dziel się cechami kulturowymi obu narodów, pozwól przyjaźni przekraczać granice i pokaż swoją kulturę światu.",
                  "nl": "TalkIn biedt meer dan 30 unieke geschenken uit verschillende landen, waarmee je cultuur kunt delen via geschenken. Wissel geschenken uit die kenmerkend zijn voor elk land met gebruikers over de hele wereld, deel de culturele kenmerken van beide landen, laat vriendschap grenzen overstijgen en toon jouw cultuur aan de wereld.",
                  "sv": "TalkIn erbjuder över 30 unika presenter från olika länder, vilket möjliggör kulturdelning genom gåvor. Byt ut gåvor som är unika för varje land med användare över hela världen, dela kulturella egenskaper från båda länderna, låt vänskap överskrida gränser och visa din kultur för världen."
                },
                "global_post": {
                  "zh-Hans": "全球贴文",
                  "zh-Hant": "全球帖文",
                  "en": "Global Post",
                  "ja": "グローバルポスト",
                  "ko": "글로벌 게시물",
                  "es": "Publicación Global",
                  "fr": "Publication Globale",
                  "de": "Weltweiter Beitrag",
                  "it": "Post Globale",
                  "pt": "Postagem Global",
                  "ru": "Глобальный Пост",
                  "tr": "Küresel Gönderi",
                  "ar": "المنشور العالمي",
                  "id": "Postingan Global",
                  "vi": "Bài đăng Toàn cầu",
                  "th": "โพสต์ทั่วโลก",
                  "hi": "वैश्विक पोस्ट",
                  "pl": "Globalny Post",
                  "nl": "Wereldwijde Post",
                  "sv": "Globalt Inlägg"
                },
                "global_post_text": {
                  "zh-Hans": "带你不出门也能环游世界！领略世界各地的风土人情，感受全球文化魅力。我们支持点词翻译、全文翻译，并提供互动功能如礼物赠送、回复、点赞。",
                  "zh-Hant": "帶您不出門也能環遊世界！領略世界各地的風土人情，感受全球文化魅力。我們支持點詞翻譯、全文翻譯，並提供互動功能如禮物贈送、回復、點贊。",
                  "en": "Take you around the world without leaving your home! Experience the local customs and cultures worldwide. We support word translation, full text translation, and interactive features like gift-giving, replies, and likes.",
                  "ja": "家にいながら世界一周の旅！世界中の風土や文化を体験し、世界の文化の魅力を感じましょう。単語翻訳、全文翻訳をサポートし、ギフト贈呈や返信、いいねのようなインタラクティブ機能も提供します。",
                  "ko": "집에서도 세계 일주가 가능합니다! 전 세계의 풍습과 문화를 경험하고 글로벌 문화의 매력을 느껴보세요. 우리는 단어 번역, 전문 번역을 지원하며 선물하기, 답글, 좋아요와 같은 상호 작용 기능을 제공합니다.",
                  "es": "¡Te llevamos alrededor del mundo sin salir de casa! Experimenta las costumbres locales y las culturas de todo el mundo. Apoyamos la traducción de palabras, la traducción de textos completos, y ofrecemos funciones interactivas como regalos, respuestas y me gusta.",
                  "fr": "Vous faire voyager autour du monde sans quitter votre domicile ! Découvrez les coutumes locales et les cultures du monde entier. Nous proposons la traduction de mots, la traduction de textes complets et des fonctionnalités interactives telles que l'envoi de cadeaux, les réponses et les likes.",
                  "de": "Wir bringen Sie von zu Hause aus um die Welt! Erleben Sie lokale Bräuche und Kulturen weltweit. Wir unterstützen Wortübersetzung, Volltextübersetzung und bieten interaktive Funktionen wie Geschenkversand, Antworten und Likes an.",
                  "it": "Portarti in giro per il mondo senza uscire di casa! Scopri le usanze locali e le culture di tutto il mondo. Supportiamo la traduzione di parole, la traduzione di testi completi e offriamo funzionalità interattive come l'invio di regali, risposte e mi piace.",
                  "pt": "Levá-lo ao redor do mundo sem sair de casa! Experimente os costumes locais e culturas de todo o mundo. Apoiamos a tradução de palavras, a tradução de textos completos e oferecemos funcionalidades interativas como envio de presentes, respostas e curtidas.",
                  "ru": "Путешествие вокруг света, не выходя из дома! Познакомьтесь с местными обычаями и культурами всего мира. Мы поддерживаем перевод слов, полных текстов и предлагаем интерактивные функции, такие как подарки, ответы, лайки.",
                  "tr": "Evinizden çıkmadan dünya turu! Dünyanın dört bir yanındaki yerel gelenekleri ve kültürleri keşfedin. Kelime çevirisi, tam metin çevirisi destekliyor ve hediye gönderme, yanıtlama, beğenme gibi etkileşimli özellikler sunuyoruz.",
                  "ar": "نأخذك في جولة حول العالم دون مغادرة منزلك! تجربة العادات المحلية والثقافات في جميع أنحاء العالم. ندعم ترجمة الكلمات، ترجمة النصوص الكاملة، ونقدم ميزات تفاعلية مثل إرسال الهدايا، الردود، والإعجابات.",
                  "id": "Membawamu keliling dunia tanpa keluar rumah! Rasakan adat istiadat lokal dan budaya dari seluruh dunia. Kami mendukung terjemahan kata, terjemahan teks lengkap, dan menyediakan fitur interaktif seperti pengiriman hadiah, balasan, dan suka.",
                  "vi": "Đưa bạn đi vòng quanh thế giới mà không cần ra khỏi nhà! Trải nghiệm phong tục và văn hóa địa phương trên toàn thế giới. Chúng tôi hỗ trợ dịch từng từ, dịch toàn bộ văn bản, và cung cấp các tính năng tương tác như gửi quà, trả lời, và thích.",
                  "th": "พาคุณเที่ยวรอบโลกโดยไม่ต้องออกจากบ้าน! สัมผัสกับประเพณีท้องถิ่นและวัฒนธรรมทั่วโลก พร้อมการแปลคำและแปลเต็มข้อความ และมีฟังก์ชันโต้ตอบ เช่น การส่งของขวัญ การตอบกลับ และการกดไลค์",
                  "hi": "आपको घर से बाहर निकले बिना दुनिया भर में ले जाएं! दुनिया भर की स्थानीय परंपराओं और संस्कृतियों का अनुभव करें। हम शब्द अनुवाद, पूर्ण पाठ अनुवाद का समर्थन करते हैं, और उपहार भेजने, उत्तर देने, और लाइक करने जैसी इंटरैक्टिव सुविधाएँ प्रदान करते हैं।",
                  "pl": "Zabierzemy Cię w podróż dookoła świata, nie wychodząc z domu! Doświadcz lokalnych zwyczajów i kultur na całym świecie. Wspieramy tłumaczenie słów, tłumaczenie pełnych tekstów i oferujemy funkcje interaktywne, takie jak wysyłanie prezentów, odpowiedzi i polubienia.",
                  "nl": "Wij nemen u mee op een wereldreis zonder uw huis te verlaten! Ervaar lokale gebruiken en culturen over de hele wereld. Wij ondersteunen woordvertaling, volledige tekstvertaling en bieden interactieve functies zoals het geven van cadeaus, reageren en likes.",
                  "sv": "Ta dig runt om i världen utan att lämna ditt hem! Upplev lokala seder och kulturer över hela världen. Vi stödjer ordöversättning, fulltextöversättning och erbjuder interaktiva funktioner som gåvogiv"
                },
                "Learn_lang_video": {
                  "zh-Hans": "看视频学语言",
                  "zh-Hant": "看視頻學語言",
                  "en": "Learn Languages by Watching Videos",
                  "ja": "動画で言語を学ぶ",
                  "ko": "비디오 보며 언어 배우기",
                  "es": "Aprender idiomas viendo videos",
                  "fr": "Apprendre des langues en regardant des vidéos",
                  "de": "Sprachen lernen durch Videos ansehen",
                  "it": "Imparare le lingue guardando video",
                  "pt": "Aprender línguas assistindo a vídeos",
                  "ru": "Изучать языки, смотря видео",
                  "tr": "Videolar İzleyerek Dil Öğrenmek",
                  "ar": "تعلم اللغات بمشاهدة الفيديوهات",
                  "id": "Belajar Bahasa dengan Menonton Video",
                  "vi": "Học ngôn ngữ qua video",
                  "th": "เรียนภาษาผ่านวิดีโอ",
                  "hi": "वीडियो देखकर भाषाएँ सीखें",
                  "pl": "Uczyć się języków przez oglądanie filmów",
                  "nl": "Talen leren door video's te bekijken",
                  "sv": "Lära sig språk genom att titta på videor"
                },
                "Learn_lang_video_text": {
                  "zh-Hans": "多语种海量电影、MV、预告片，带你边看视频边学语言.学习更高效，学习更有趣",
                  "zh-Hant": "多語種海量電影、MV、預告片，帶你邊看視頻邊學語言。學習更高效，學習更有趣",
                  "en": "Multilingual vast collection of movies, MVs, and trailers, learn languages while watching videos. Learning is more efficient and more fun.",
                  "ja": "多言語の大量映画、MV、予告編で、動画を見ながら言語学習。もっと効率的で、もっと楽しく学べます。",
                  "ko": "다양한 언어의 대량 영화, MV, 예고편으로 비디오를 보면서 언어를 배웁니다. 학습이 더 효율적이고 재미있습니다.",
                  "es": "Amplia variedad de películas, MVs y tráilers en varios idiomas, aprende idiomas mientras ves videos. Aprender es más eficiente y más divertido.",
                  "fr": "Une vaste collection de films, de MV et de bandes-annonces en plusieurs langues, apprenez les langues en regardant des vidéos. L'apprentissage est plus efficace et plus amusant.",
                  "de": "Eine große Auswahl an Filmen, MVs und Trailern in verschiedenen Sprachen, lerne Sprachen beim Ansehen von Videos. Lernen ist effizienter und macht mehr Spaß.",
                  "it": "Un'ampia raccolta di film, MV e trailer in diverse lingue, impara le lingue guardando video. L'apprendimento è più efficiente e più divertente.",
                  "pt": "Uma vasta coleção de filmes, MVs e trailers em várias línguas, aprenda idiomas enquanto assiste a vídeos. Aprender é mais eficiente e mais divertido.",
                  "ru": "Большая коллекция фильмов, музыкальных видео и трейлеров на разных языках, изучайте языки, смотря видео. Обучение более эффективное и интересное.",
                  "tr": "Çok dilli geniş film, MV ve fragman koleksiyonu, videoları izlerken dilleri öğrenin. Öğrenme daha verimli ve daha eğlenceli.",
                  "ar": "مجموعة ضخمة من الأفلام والموسيقى الفيديو والإعلانات الترويجية بعدة لغات، تعلم اللغات أثناء مشاهدة الفيديوهات. التعلم أكثر فعالية ومتعة.",
                  "id": "Koleksi besar film, MV, dan trailer multibahasa, belajar bahasa sambil menonton video. Belajar menjadi lebih efisien dan lebih menyenangkan.",
                  "vi": "Bộ sưu tập phim, MV, trailer đa ngôn ngữ, học ngôn ngữ trong khi xem video. Học tập hiệu quả hơn và thú vị hơn.",
                  "th": "ภาพยนตร์, MV, และตัวอย่างภาพยนตร์จำนวนมากหลายภาษา, เรียนภาษาขณะดูวิดีโอ การเรียนรู้ที่มีประสิทธิภาพและสนุกยิ่งขึ้น",
                  "hi": "बहुभाषी विशाल संग्रह वाली फिल्में, MVs, और ट्रेलर, वीडियो देखते समय भाषाएं सीखें। सीखना अधिक कुशल और मजेदार है।",
                  "pl": "Obszerna kolekcja filmów, teledysków i zwiastunów w wielu językach, ucz się języków, oglądając filmy. Nauka jest bardziej efektywna i przyjemniejsza.",
                  "nl": "Een uitgebreide collectie van films, MV's en trailers in verschillende talen, leer talen terwijl je video's bekijkt. Leren is efficiënter en leuker.",
                  "sv": "Ett stort utbud av filmer, musikvideor och trailers på flera språk, lär dig språk medan du tittar på videor. Inlärningen är mer effektiv och roligare."
                },
                "copyright": {
                  "zh-Hans": "深圳章鱼乐园科技合伙企业 (有限合伙) 版权所有",
                  "zh-Hant": "深圳章鱼乐园科技合伙企业 (有限合伙) 版权所有",
                  "en": "Copyright © Shenzhen Octopus Paradise Technology Partnership (Limited Partnership)",
                  "ja": "深センタコパラダイステクノロジーパートナーシップ（有限パートナーシップ）著作権所有",
                  "ko": "심천 장어 놀이공원 기술 합자 회사 (유한 합자) 저작권 소유",
                  "es": "Todos los derechos reservados por Sociedad de Responsabilidad Limitada de Tecnología Parque de Pulpos Shenzhen (Sociedad Comanditaria)",
                  "fr": "Tous droits réservés par Partenariat Technologique Parc des Pieuvres de Shenzhen (Société en Commandite)",
                  "de": "Alle Rechte vorbehalten von Shenzhen Oktopus-Freizeitpark Technologie Kommanditgesellschaft (KG)",
                  "it": "Tutti i diritti riservati da Partnership Tecnologico Parco dei Polpi di Shenzhen (Partnership a Responsabilità Limitata)",
                  "pt": "Todos os direitos reservados por Parceria Tecnológica Parque dos Polvos Shenzhen (Sociedade em Comandita)",
                  "ru": "Все права защищены Технологическим партнёрством Осьминог Парк Шэньчжэнь (Ограниченное партнёрство)",
                  "tr": "Shenzhen Ahtapot Eğlence Parkı Teknoloji Ortaklığı (Sınırlı Ortaklık) tarafından tüm hakları saklıdır",
                  "ar": "جميع الحقوق محفوظة لشراكة حديقة الأخطبوط التكنولوجية بشينزين (شراكة محدودة)",
                  "id": "Hak cipta dilindungi undang-undang oleh Kemitraan Teknologi Taman Gurita Shenzhen (Kemitraan Terbatas)",
                  "vi": "Bản quyền đã được bảo hộ bởi Công Ty Hợp Danh Công Nghệ Công Viên Bạch Tuộc Thâm Quyến (Hợp Danh Có Hạn)",
                  "th": "สงวนลิขสิทธิ์โดยห้างหุ้นส่วนจำกัดเทคโนโลยีสวนปลาหมึกเซินเจิ้น",
                  "hi": "शेन्ज़ेन ऑक्टोपस पार्क टेक्नोलॉजी पार्टनरशिप (लिमिटेड पार्टनरशिप) के सर्वाधिकार सुरक्षित",
                  "pl": "Wszelkie prawa zastrzeżone przez Spółkę Partnerską Technologia Parku Ośmiornic Shenzhen (Spółka Komandytowa)",
                  "nl": "Alle rechten voorbehouden door Shenzhen Octopus Park Technologie Vennootschap (Commanditaire Vennootschap)",
                  "sv": "Alla rättigheter förbehållna av Shenzhen Bläckfisk Lekland Teknikbolag (Kommanditbolag)"
                },
                "language_switch": {
                  "zh-Hans": "切换语言",
                  "zh-Hant": "語言",
                  "en": "Language",
                  "ja": "言語",
                  "ko": "언어",
                  "es": "Idioma",
                  "fr": "Langue",
                  "de": "Sprache",
                  "it": "Lingua",
                  "pt": "Língua",
                  "ru": "Язык",
                  "tr": "Dil",
                  "ar": "لغة",
                  "id": "Bahasa",
                  "vi": "Ngôn ngữ",
                  "th": "ภาษา",
                  "hi": "भाषा",
                  "pl": "Język",
                  "nl": "Taal",
                  "sv": "Språk"
                },
                "contact_us": {
                  "zh-Hans": "联系我们",
                  "zh-Hant": "聯絡我們",
                  "en": "Contact Us",
                  "ja": "お問い合わせ",
                  "ko": "문의하기",
                  "es": "Contáctanos",
                  "fr": "Contactez-nous",
                  "de": "Kontaktieren Sie uns",
                  "it": "Contattaci",
                  "pt": "Contate-nos",
                  "ru": "Свяжитесь с нами",
                  "tr": "Bize Ulaşın",
                  "ar": "اتصل بنا",
                  "id": "Hubungi Kami",
                  "vi": "Liên hệ chúng tôi",
                  "th": "ติดต่อเรา",
                  "hi": "हमसे संपर्क करें",
                  "pl": "Skontaktuj się z nami",
                  "nl": "Neem Contact Met Ons Op",
                  "sv": "Kontakta Oss"
                },
                "you_have_question": {
                  "zh-Hans": "如果您有任何问题，",
                  "zh-Hant": "如果您有任何問題，",
                  "en": "If you have any questions,",
                  "ja": "ご質問があれば、",
                  "ko": "질문이 있으시다면,",
                  "es": "Si tiene alguna pregunta,",
                  "fr": "Si vous avez des questions,",
                  "de": "Wenn Sie Fragen haben,",
                  "it": "Se avete domande,",
                  "pt": "Se você tem alguma dúvida,",
                  "ru": "Если у вас есть вопросы,",
                  "tr": "Herhangi bir sorunuz varsa,",
                  "ar": "إذا كان لديك أي أسئلة،",
                  "id": "Jika Anda memiliki pertanyaan,",
                  "vi": "Nếu bạn có bất kỳ câu hỏi nào,",
                  "th": "หากคุณมีคำถามใด ๆ",
                  "hi": "यदि आपके कोई प्रश्न हों,",
                  "pl": "Jeśli masz jakieś pytania,",
                  "nl": "Als u vragen heeft,",
                  "sv": "Om du har några frågor,"
                },
                "can_contact_us": {
                  "zh-Hans": "请随时联系我们",
                  "zh-Hant": "請隨時聯繫我們",
                  "en": "Please feel free to contact us",
                  "ja": "いつでもお気軽にお問い合わせください",
                  "ko": "언제든지 저희에게 연락해 주세요",
                  "es": "Por favor, no dude en contactarnos",
                  "fr": "N'hésitez pas à nous contacter",
                  "de": "Bitte kontaktieren Sie uns jederzeit",
                  "it": "Non esitate a contattarci",
                  "pt": "Por favor, não hesite em nos contactar",
                  "ru": "Пожалуйста, свяжитесь с нами в любое время",
                  "tr": "Lütfen bizimle iletişime geçmekten çekinmeyin",
                  "ar": "لا تتردد في الاتصال بنا",
                  "id": "Silakan hubungi kami kapan saja",
                  "vi": "Hãy liên hệ với chúng tôi bất cứ khi nào",
                  "th": "โปรดติดต่อเราได้ตลอดเวลา",
                  "hi": "कृपया किसी भी समय हमसे संपर्क करें",
                  "pl": "Proszę skontaktować się z nami w dowolnym czasie",
                  "nl": "Neem gerust op elk moment contact met ons op",
                  "sv": "Tveka inte att kontakta oss när som helst"
                },
                "email": {
                  "zh-Hans": "邮箱",
                  "zh-Hant": "郵箱",
                  "en": "Email",
                  "ja": "メールボックス",
                  "ko": "이메일",
                  "es": "Correo electrónico",
                  "fr": "Boîte mail",
                  "de": "E-Mail-Box",
                  "it": "Casella di posta",
                  "pt": "Caixa de e-mail",
                  "ru": "Почтовый ящик",
                  "tr": "E-posta kutusu",
                  "ar": "صندوق البريد",
                  "id": "Kotak surat",
                  "vi": "Hộp thư",
                  "th": "กล่องจดหมาย",
                  "hi": "ईमेल बॉक्स",
                  "pl": "Skrzynka pocztowa",
                  "nl": "E-mailbox",
                  "sv": "E-postlåda"
                },
                "telephone": {
                  "zh-Hans": "电话",
                  "zh-Hant": "電話",
                  "en": "Telephone",
                  "ja": "電話",
                  "ko": "전화",
                  "es": "Teléfono",
                  "fr": "Téléphone",
                  "de": "Telefon",
                  "it": "Telefono",
                  "pt": "Telefone",
                  "ru": "Телефон",
                  "tr": "Telefon",
                  "ar": "هاتف",
                  "id": "Telepon",
                  "vi": "Điện thoại",
                  "th": "โทรศัพท์",
                  "hi": "टेलीफोन",
                  "pl": "Telefon",
                  "nl": "Telefoon",
                  "sv": "Telefon"
                },
                "our_social_media": {
                  "zh-Hans": "我们的社媒",
                  "zh-Hant": "我們的社交媒體",
                  "en": "Our Social Media",
                  "ja": "私たちのソーシャルメディア",
                  "ko": "우리의 소셜 미디어",
                  "es": "Nuestros Medios Sociales",
                  "fr": "Nos Médias Sociaux",
                  "de": "Unsere Sozialen Medien",
                  "it": "I Nostri Social Media",
                  "pt": "Nossas Mídias Sociais",
                  "ru": "Наши Социальные Сети",
                  "tr": "Sosyal Medyamız",
                  "ar": "وسائل التواصل الاجتماعي لدينا",
                  "id": "Media Sosial Kami",
                  "vi": "Mạng Xã Hội của Chúng Tôi",
                  "th": "โซเชียลมีเดียของเรา",
                  "hi": "हमारा सोशल मीडिया",
                  "pl": "Nasze Media Społecznościowe",
                  "nl": "Onze Social Media",
                  "sv": "Våra Sociala Medier"
                },
                "address": {
                  "zh-Hans": "地址",
                  "zh-Hant": "地址",
                  "en": "Address",
                  "ja": "住所",
                  "ko": "주소",
                  "es": "Dirección",
                  "fr": "Adresse",
                  "de": "Adresse",
                  "it": "Indirizzo",
                  "pt": "Endereço",
                  "ru": "Адрес",
                  "tr": "Adres",
                  "ar": "العنوان",
                  "id": "Alamat",
                  "vi": "Địa chỉ",
                  "th": "ที่อยู่",
                  "hi": "पता",
                  "pl": "Adres",
                  "nl": "Adres",
                  "sv": "Adress"
                },
                "position": {
                  "zh-Hans": "中国,广东省深圳市南山区软件园二期11栋 8楼 802",
                  "zh-Hant": "中國，廣東省深圳市南山區軟件園二期11棟8樓 802",
                  "en": "802, 8th Floor, Building 11, Software Park Phase II, Nanshan District, Shenzhen City, Guangdong Province, China",
                  "ja": "中国、広東省深セン市南山区ソフトウェアパーク二期11棟8階 802",
                  "ko": "중국 광동성 선전시 난산구 소프트웨어 공원 이기 11동 8층 802 호",
                  "es": "802, 8ª planta, Edificio 11, Parque de Software Fase II, Distrito de Nanshan, Ciudad de Shenzhen, Provincia de Guangdong, China",
                  "fr": "802, 8ème étage, bâtiment 11, parc de logiciels phase II, district de Nanshan, ville de Shenzhen, province de Guangdong, Chine",
                  "de": "802, 8. Stock, Gebäude 11, Softwarepark Phase II, Bezirk Nanshan, Stadt Shenzhen, Provinz Guangdong, China",
                  "it": "802, 8° piano, edificio 11, Parco Software Fase II, distretto di Nanshan, città di Shenzhen, provincia di Guangdong, Cina",
                  "pt": "802, 8º andar, Edifício 11, Parque de Software Fase II, Distrito de Nanshan, Cidade de Shenzhen, Província de Guangdong, China",
                  "ru": "802, 8 этаж, здание 11, Софтверный парк фаза II, район Наньшань, город Шэньчжэнь, провинция Гуандун, Китай",
                  "tr": "Çin, Guangdong Eyaleti, Shenzhen Şehri, Nanshan Bölgesi, Yazılım Parkı Faz II, Bina 11, 8. Kat, 802",
                  "ar": "802، الطابق الثامن، مبنى 11، المرحلة الثانية من حديقة البرمجيات، منطقة نانشان، مدينة شينزين، مقاطعة غوانغدونغ، الصين",
                  "id": "802, Lantai 8, Gedung 11, Taman Perangkat Lunak Fase II, Distrik Nanshan, Kota Shenzhen, Provinsi Guangdong, Cina",
                  "vi": "802, Tầng 8, Tòa nhà 11, Công viên Phần mềm Giai đoạn II, Quận Nanshan, Thành phố Thâm Quyến, Tỉnh Quảng Đông, Trung Quốc",
                  "th": "802, ชั้น 8, อาคาร 11, ซอฟต์แวร์พาร์คเฟส II, เขตหนานซาน, เมืองเซินเจิ้น, มณฑลกวางตุ้ง, จีน",
                  "hi": "802, 8वीं मंजिल, बिल्डिंग 11, सॉफ्टवेयर पार्क फेज II, नानशान जिला, शेन्ज़ेन शहर, ग्वांगडोंग प्रांत, चीन",
                  "pl": "802, 8. piętro, budynek 11, Park Oprogramowania Faza II, dzielnica Nanshan, miasto Shenzhen, prowincja Guangdong, Chiny",
                  "nl": "802, 8e verdieping, Gebouw 11, Softwarepark Fase II, District Nanshan, Stad Shenzhen, Provincie Guangdong, China",
                  "sv": "802, 8:e våningen, Byggnad 11, Programvaruparken Fas II, Nanshan-distriktet, Staden Shenzhen, Guangdong-provinsen, Kina"
                },
                "work_time": {
                  "zh-Hans": "工作时间",
                  "zh-Hant": "工作時間",
                  "en": "Working Hours",
                  "ja": "勤務時間",
                  "ko": "근무 시간",
                  "es": "Horario de trabajo",
                  "fr": "Heures de travail",
                  "de": "Arbeitszeit",
                  "it": "Orario di lavoro",
                  "pt": "Horário de trabalho",
                  "ru": "Рабочее время",
                  "tr": "Çalışma saatleri",
                  "ar": "ساعات العمل",
                  "id": "Jam Kerja",
                  "vi": "Giờ làm việc",
                  "th": "เวลาทำงาน",
                  "hi": "कार्य समय",
                  "pl": "Godziny pracy",
                  "nl": "Werkuren",
                  "sv": "Arbetstid"
                },
                "app_introduce": {
                  "zh-Hans": "应用介绍",
                  "zh-Hant": "應用介紹",
                  "en": "App Introduce",
                  "ja": "アプリ紹介",
                  "ko": "앱 소개",
                  "es": "Descripción de la aplicación",
                  "fr": "Description de l'application",
                  "de": "App-Beschreibung",
                  "it": "Descrizione dell'app",
                  "pt": "Descrição do aplicativo",
                  "ru": "Описание приложения",
                  "tr": "Uygulama Açıklaması",
                  "ar": "وصف التطبيق",
                  "id": "Deskripsi Aplikasi",
                  "vi": "Mô tả ứng dụng",
                  "th": "คำอธิบายแอป",
                  "hi": "ऐप का विवरण",
                  "pl": "Opis aplikacji",
                  "nl": "App-beschrijving",
                  "sv": "Appbeskrivning"
                },


                  "unsubscribe_notice": {
                      "zh-Hans": "取消订阅后，TalkIn将不再通过邮件为你推荐高质量母语者和语伴",
                      "zh-Hant": "取消訂閱後，TalkIn將不再透過郵件為你推薦高品質母語者和語伴",
                      "en": "After unsubscribing, TalkIn will no longer recommend high-quality native speakers and language partners via email",
                      "ja": "購読解除後、TalkInはメールで高品質なネイティブスピーカーや言語パートナーをお勧めしなくなります",
                      "ko": "구독을 취소하면 TalkIn에서 더 이상 이메일로 고품질 원어민 및 언어 파트너를 추천하지 않습니다",
                      "es": "Después de cancelar la suscripción, TalkIn ya no recomendará hablantes nativos de alta calidad y compañeros de idioma por correo electrónico",
                      "fr": "Après avoir annulé votre abonnement, TalkIn ne recommandera plus d'interlocuteurs natifs de haute qualité et de partenaires linguistiques par e-mail",
                      "de": "Nach dem Abbestellen wird TalkIn keine qualitativ hochwertigen Muttersprachler und Sprachpartner mehr per E-Mail empfehlen",
                      "it": "Dopo aver annullato l'iscrizione, TalkIn non consiglierà più madrelingua e partner linguistici di alta qualità via email",
                      "pt": "Após cancelar a inscrição, o TalkIn não recomendará mais falantes nativos de alta qualidade e parceiros de idioma por e-mail",
                      "ru": "После отмены подписки TalkIn больше не будет рекомендовать носителей языка и языковых партнеров по электронной почте",
                      "tr": "Aboneliği iptal ettikten sonra, TalkIn artık e-posta yoluyla yüksek kaliteli ana dili konuşanlar ve dil partnerleri önermeyecek",
                      "ar": "بعد إلغاء الاشتراك، لن يوصي TalkIn بعد الآن بالناطقين الأصليين ذوي الجودة العالية وشركاء اللغة عبر البريد الإلكتروني",
                      "id": "Setelah berhenti berlangganan, TalkIn tidak akan lagi merekomendasikan penutur asli berkualitas tinggi dan mitra bahasa melalui email",
                      "vi": "Sau khi hủy đăng ký, TalkIn sẽ không còn giới thiệu những người nói ngôn ngữ bản địa chất lượng cao và đối tác ngôn ngữ qua email",
                      "th": "หลังจากยกเลิกการสมัครแล้ว TalkIn จะไม่แนะนำผู้พูดเจ้าของภาษาคุณภาพสูงและคู่ภาษาทางอีเมลอีกต่อไป",
                      "hi": "सदस्यता रद्द करने के बाद, TalkIn अब उच्च-गुणवत्ता वाले देशी वक्ताओं और भाषा साझेदारों की सिफारिश ईमेल के माध्यम से नहीं करेगा",
                      "pl": "Po rezygnacji z subskrypcji TalkIn nie będzie już polecać wysokiej jakości rodzimych użytkowników języka i partnerów językowych za pośrednictwem poczty elektronicznej",
                      "nl": "Na het opzeggen van het abonnement zal TalkIn geen hoogwaardigsprekers en taalpartners meer via e-mail aanbevelen",
                      "sv": "Efter att ha avslutat prenumerationen kommer TalkIn inte längre att rekommendera högkvalitativa modersmålstalare och språkpartners via e-post"
                  },
                  "confirm": {
                      "zh-Hans": "确认",
                      "zh-Hant": "確認",
                      "en": "Confirm",
                      "ja": "確認",
                      "ko": "확인",
                      "es": "Confirmar",
                      "fr": "Confirmer",
                      "de": "Bestätigen",
                      "it": "Conferma",
                      "pt": "Confirmar",
                      "ru": "Подтвердить",
                      "tr": "Onayla",
                      "ar": "تأكيد",
                      "id": "Konfirmasi",
                      "vi": "Xác nhận",
                      "th": "ยืนยัน",
                      "hi": "पुष्टि करें",
                      "pl": "Potwierdź",
                      "nl": "Bevestigen",
                      "sv": "Bekräfta"
                  },
                  "cancel": {
                      "zh-Hans": "取消",
                      "zh-Hant": "取消",
                      "en": "Cancel",
                      "ja": "キャンセル",
                      "ko": "취소",
                      "es": "Cancelar",
                      "fr": "Annuler",
                      "de": "Abbrechen",
                      "it": "Annulla",
                      "pt": "Cancelar",
                      "ru": "Отмена",
                      "tr": "İptal",
                      "ar": "إلغاء",
                      "id": "Batal",
                      "vi": "Hủy",
                      "th": "ยกเลิก",
                      "hi": "रद्द करें",
                      "pl": "Anuluj",
                      "nl": "Annuleren",
                      "sv": "Avbryt"
                  },
                  "contact_for_question": {
                      "zh-Hans": "对此邮件有疑问？联系我们",
                      "zh-Hant": "對此郵件有疑問？聯絡我們",
                      "en": "Have questions about this email? Contact us",
                      "ja": "このメールについて質問がありますか？お問い合わせください",
                      "ko": "이 이메일에 대한 질문이 있습니까? 문의하세요",
                      "es": "¿Tienes preguntas sobre este correo? Contáctanos",
                      "fr": "Des questions sur cet e-mail ? Contactez-nous",
                      "de": "Fragen zu dieser E-Mail? Kontaktieren Sie uns",
                      "it": "Hai domande su questa email? Contattaci",
                      "pt": "Tem dúvidas sobre este e-mail? Contacte-nos",
                      "ru": "Есть вопросы по этому письму? Свяжитесь с нами",
                      "tr": "Bu e-posta hakkında sorularınız mı var? Bize ulaşın",
                      "ar": "هل لديك أسئلة حول هذا البريد الإلكتروني؟ اتصل بنا",
                      "id": "Ada pertanyaan tentang email ini? Hubungi kami",
                      "vi": "Có thắc mắc về email này? Liên hệ với chúng tôi",
                      "th": "มีคำถามเกี่ยวกับอีเมลนี้ไหม ติดต่อเรา",
                      "hi": "इस ईमेल के बारे में प्रश्न हैं? हमसे संपर्क करें",
                      "pl": "Masz pytania dotyczące tej wiadomości e-mail? Skontaktuj się z nami",
                      "nl": "Vragen over deze e-mail? Neem contact met ons op",
                      "sv": "Har du frågor om detta e-postmeddelande? Kontakta oss"
                  },
                  "talkin_slogan": {
                      "zh-Hans": "世界无界,聊在TalkIn",
                      "zh-Hant": "世界無界,聊在TalkIn",
                      "en": "Beyond Borders,\nTalk In TalkIn",
                      "ja": "Beyond Borders,\nTalk In TalkIn",
                      "ko": "Beyond Borders,\nTalk In TalkIn",
                      "es": "Beyond Borders,\nTalk In TalkIn",
                      "fr": "Beyond Borders,\nTalk In TalkIn",
                      "de": "Beyond Borders,\nTalk In TalkIn",
                      "it": "Beyond Borders,\nTalk In TalkIn",
                      "pt": "Beyond Borders,\nTalk In TalkIn",
                      "ru": "Beyond Borders,\nTalk In TalkIn",
                      "tr": "Beyond Borders,\nTalk In TalkIn",
                      "ar": "Beyond Borders,\nTalk In TalkIn",
                      "id": "Beyond Borders,\nTalk In TalkIn",
                      "vi": "Beyond Borders,\nTalk In TalkIn",
                      "th": "Beyond Borders,\nTalk In TalkIn",
                      "hi": "Beyond Borders,\nTalk In TalkIn",
                      "pl": "Beyond Borders,\nTalk In TalkIn",
                      "nl": "Beyond Borders,\nTalk In TalkIn",
                      "sv": "Beyond Borders,\nTalk In TalkIn"
                  },
                  // "contact_us": {
                  //     "zh-Hans": "联系我们",
                  //     "zh-Hant": "聯絡我們",
                  //     "en": "Contact Us",
                  //     "ja": "お問い合わせ",
                  //     "ko": "문의하기",
                  //     "es": "Contáctenos",
                  //     "fr": "Contactez-nous",
                  //     "de": "Kontaktieren Sie uns",
                  //     "it": "Contattaci",
                  //     "pt": "Contacte-nos",
                  //     "ru": "Свяжитесь с нами",
                  //     "tr": "Bizimle iletişime geçin",
                  //     "ar": "اتصل بنا",
                  //     "id": "Hubungi Kami",
                  //     "vi": "Liên hệ với chúng tôi",
                  //     "th": "ติดต่อเรา",
                  //     "hi": "हमसे संपर्क करें",
                  //     "pl": "Skontaktuj się z nami",
                  //     "nl": "Neem contact met ons op",
                  //     "sv": "Kontakta oss"
                  // },
                  "unsubscribed_successful": {
                      "zh-Hans": "已取消订阅此邮件",
                      "zh-Hant": "已取消訂閱此郵件",
                      "en": "Unsubscribed from this email",
                      "ja": "このメールの購読を解除しました",
                      "ko": "이 이메일의 구독이 취소되었습니다",
                      "es": "Dado de baja de este correo electrónico",
                      "fr": "Désabonné de cet e-mail",
                      "de": "Abbestellt von dieser E-Mail",
                      "it": "Annullato l'iscrizione da questa email",
                      "pt": "Cancelado a inscrição deste e-mail",
                      "ru": "Отписались от этой электронной почты",
                      "tr": "Bu e-postadan çıkıldı",
                      "ar": "تم إلغاء الاشتراك في هذا البريد الإلكتروني",
                      "id": "Berhenti berlangganan dari email ini",
                      "vi": "Đã hủy đăng ký email này",
                      "th": "ยกเลิกการสมัครอีเมลนี้แล้ว",
                      "hi": "इस ईमेल की सदस्यता समाप्त कर दी गई है",
                      "pl": "Zrezygnowano z subskrypcji tego e-maila",
                      "nl": "Uitgeschreven voor deze e-mail",
                      "sv": "Avprenumererad från detta e-postmeddelande"
                  },
                  "no_more_recommendations": {
                      "zh-Hans": "TalkIn将不再通过邮件为你推荐高质量语伴",
                      "zh-Hant": "TalkIn將不再透過郵件為你推薦高品質語伴",
                      "en": "TalkIn will no longer recommend high-quality language partners via email",
                      "ja": "TalkInはもうメールで高品質な言語パートナーをお勧めしなくなります",
                      "ko": "TalkIn에서 더 이상 이메일로 고품질 언어 파트너를 추천하지 않습니다",
                      "es": "TalkIn ya no recomendará compañeros de idioma de alta calidad por correo electrónico",
                      "fr": "TalkIn ne recommandera plus de partenaires linguistiques de haute qualité par e-mail",
                      "de": "TalkIn wird keine hochwertigen Sprachpartner mehr per E-Mail empfehlen",
                      "it": "TalkIn non consiglierà più partner linguistici di alta qualità via email",
                      "pt": "TalkIn não recomendará mais parceiros de idioma de alta qualidade por e-mail",
                      "ru": "TalkIn больше не будет рекомендовать языковых партнеров высокого качества по электронной почте",
                      "tr": "TalkIn artık e-posta yoluyla yüksek kaliteli dil partnerlerini önermeyecek",
                      "ar": "لن يوصي TalkIn بعد الآن بشركاء لغويين ذوي جودة عالية عبر البريد الإلكتروني",
                      "id": "TalkIn tidak akan lagi merekomendasikan mitra bahasa berkualitas tinggi melalui email",
                      "vi": "TalkIn sẽ không còn giới thiệu đối tác ngôn ngữ chất lượng cao qua email",
                      "th": "TalkIn จะไม่แนะนำคู่ภาษาคุณภาพสูงทางอีเมลอีกต่อไป",
                      "hi": "TalkIn अब ईमेल के माध्यम से उच्च गुणवत्ता वाले भाषा साझेदारों की सिफारिश नहीं करेगा",
                      "pl": "TalkIn nie będzie już polecać wysokiej jakości partnerów językowych za pośrednictwem poczty elektronicznej",
                      "nl": "TalkIn zal niet langer taalpartners van hoge kwaliteit via e-mail aanbevelen",
                      "sv": "TalkIn kommer inte längre att rekommendera högkvalitativa språkpartners via e-post"
                  },

                  "edm_unsubscribe": {
                    "zh-Hans": "取消订阅",
                    "zh-Hant": "取消訂閱",
                    "en": "Unsubscribe",
                    "ja": "退会する",
                    "ko": "구독 취소",
                    "es": "Cancelar suscripción",
                    "fr": "Se désabonner",
                    "de": "Abbestellen",
                    "it": "Annulla l'iscrizione",
                    "pt": "Cancelar subscrição",
                    "ru": "Отписаться",
                    "tr": "Aboneliği iptal et",
                    "ar": "إلغاء الاشتراك",
                    "id": "Berhenti berlangganan",
                    "vi": "Hủy đăng ký",
                    "th": "ยกเลิกการสมัครสมาชิก",
                    "hi": "सदस्यता रद्द करें",
                    "pl": "Anuluj subskrypcję",
                    "nl": "Uitschrijven",
                    "sv": "Avsluta prenumerationen"
                },
                "scan_download": {
                  "zh-Hans": "扫码下载",
                  "zh-Hant": "掃碼下載",
                  "en": "Scan to Download",
                  "ja": "スキャンしてダウンロード",
                  "ko": "스캔하여 다운로드",
                  "es": "Escanea para descargar",
                  "fr": "Scannez pour télécharger",
                  "de": "Scannen, um herunterzuladen",
                  "it": "Scansiona per scaricare",
                  "pt": "Escaneie para baixar",
                  "ru": "Сканируйте для загрузки",
                  "tr": "Tarayıp indir",
                  "ar": "امسح لتنزيل",
                  "id": "Pindai untuk mengunduh",
                  "vi": "Quét mã để tải xuống",
                  "th": "สแกนเพื่อดาวน์โหลด",
                  "hi": "डाउनलोड करने के लिए स्कैन करें",
                  "pl": "Zeskanuj, aby pobrać",
                  "nl": "Scan om te downloaden",
                  "sv": "Skanna för att ladda ner"
              },
              "conversing_with_native": {
                "zh-Hans": "与母语者&交流",
                "zh-Hant": "與母語者&交流",
                "en": "Conversing& With Native Speakers",
                "ja": "ネイティブスピーカーと&交流する",
                "ko": "원어민과& 소통하기",
                "es": "Comunícate& con nativos",
                "fr": "Communiquez& avec les natifs",
                "de": "Mit Muttersprachlern& kommunizieren",
                "it": "Comunica& con madrelingua",
                "pt": "Comunique-se& com nativos",
                "ru": "Общайтесь& с носителями языка",
                "tr": "Anadili& konuşanlarla iletişim",
                "ar": "التواصل مع& المتحدثين الأصليين",
                "id": "Berkomunikasi& dengan penutur asli",
                "vi": "Giao tiếp& với người bản xứ",
                "th": "สื่อสารกับเจ้าของภาษา",
                "hi": "देशी वक्ताओं& के साथ संवाद करें",
                "pl": "Komunikuj& z native speakerami",
                "nl": "Communiceer& met moedertaalsprekers",
                "sv": "Kommunicera& med infödda"
            },
            "practice_language":{
              "zh-Hans": "练外语，看世界",
              "zh-Hant": "練外語，看世界",
              "en": "Practice languages, see the world",
              "ja": "外国語を練習して、世界を見る",
              "ko": "외국어를 연습하고 세상을 보다",
              "es": "Practica idiomas, mira el mundo",
              "fr": "Pratiquez les langues étrangères, découvrez le monde",
              "de": "Fremdsprachen üben, die Welt sehen",
              "it": "Pratica le lingue straniere, guarda il mondo",
              "pt": "Pratique idiomas, veja o mundo",
              "ru": "Практикуйте иностранные языки, смотрите мир",
              "tr": "Yabancı dil pratiği yap, dünyayı gör",
              "ar": "تدرب على اللغات الأجنبية، شاهد العالم",
              "id": "Latih bahasa asing, lihat dunia",
              "vi": "Luyện ngoại ngữ, nhìn ra thế giới",
              "th": "ฝึกภาษาต่างประเทศ มองโลก",
              "hi": "विदेशी भाषाओं का अभ्यास करें, दुनिया देखें",
              "pl": "Ćwicz języki obce, zobacz świat",
              "nl": "Oefen vreemde talen, zie de wereld",
              "sv": "Träna främmande språk, se världen"
          }
        }
    }
    getLangTxt(key) {
        // console.log(key, this.lang, this.jsonData)
        const item = this.jsonData[key]
        if(item){
            return item[this.lang] || ''
        } else {
            return ''
        }
    }
}
var lang = new navigatorLanguage().lang
var tranTxt = new tranTxtLang(lang)
// 函数获取翻译
// function getTranFromKey(key) {
//     return tranTxt.getLangTxt(key)
// }
Vue.prototype.$lang = lang
Vue.prototype.getTranFromKey = (key) => {
    return tranTxt.getLangTxt(key)
}

Vue.prototype.themeType = 'black';

// 跳转链接
Vue.prototype.$TurnIosOrAndroid = (type) => {
  var url = ''
  if (type === 1) {
    url = 'itms-apps://itunes.apple.com/app/id6467084338'
  } else {
    url = 'https://play.google.com/store/apps/details?id=com.talkin.learn'
  }
  window.location.href = url
}

window.onload = () => {
  const dom = document.getElementsByClassName('page-home')
  if (dom.length === 0) {
    return
  }
  const cname = dom[0].className.replace('high-resolution', '').replace('mobile', '')
  if (dom[0].clientWidth < 800) {
    dom[0].className = cname + ' mobile'
  } else if (dom[0].clientWidth > 1920) {
    dom[0].className = cname + ' high-resolution'
  }
}