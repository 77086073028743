import request from '@/utils/request'

// 更新email订阅状态
export const updateEmailSubscribeState = (data) => {
  return request({
    url: '/email/v1/subscribe-state',
    method: 'put',
    data
  })
}
