<template>
    <div class="store-view page-home" :class="{'mobile': isMobile}">
        <FixNav />
        <div class="s-contain">
            <!--<div class="s-slogan">
                <p>Join TalkIn</p>
                <p>Language Learning and Global Social Revolution!</p>
            </div>
            <div class="s-sign">
                <img draggable="false" class="img" src="@/assets/images/store/b1.png" />
            </div>
            <div class="s-desc">
                <p>TalkIn is a youthful language learning and social application, and also a platform for connecting with high-quality language partners from around the world. We aim to create a vibrant and interactive language social circle. We don't do language corners; what we offer is a global language social party.</p>
                <p>Do you want to find global language partners to practice speaking with? Do you want to learn about the cultural characteristics of different countries? Do you want to practice foreign language pronunciation? Do you want to find a trustworthy friend in a foreign country? Do you want to showcase the most interesting aspects of your own country's culture? Do you want to make friends while traveling?</p>
                <p>We can help you with all of this, allowing you to meet global language partners and have exciting conversations from the comfort of your home.</p>
                <p>TalkIn makes everything accessible and turns the world into your home!</p>
            </div>
            <div class="s-exhibit">
                <img draggable="false" src="@/assets/images/store/s1.png" class="s-img1" />
                <img draggable="false" src="@/assets/images/store/s2.png" class="s-img2" />
                <img draggable="false" src="@/assets/images/store/s3.png" class="s-img3" />
                <img draggable="false" src="@/assets/images/store/s4.png" class="s-img4" />
                <img draggable="false" src="@/assets/images/store/s5.png" class="s-img5" />
                <img draggable="false" src="@/assets/images/store/s6.png" class="s-img6" />
            </div>
            <div class="exhibit-desc">
                <ul class="e-list">
                    <li class="e-row">
                        <h3>●🎁 Global Gifts: </h3>
                        <p>TalkIn offers exclusive cultural gifts from over 30 different countries, allowing you to share culture through gifts and build friendships across borders!</p>
                    </li>
                    <li class="e-row">
                        <h3>● 🆓Free Lifetime Translation: </h3>
                        <p>TalkIn provides unlimited, lifetime translation services across the platform, ensuring seamless communication for users around the world. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🧑‍🤝‍🧑Efficient Matching Algorithm:</h3>
                        <p>Our multidimensional information processing technology can quickly match you with your ideal language partner worldwide, enhancing learning efficiency and social experience. </p>
                    </li>
                    <li class="e-row">
                        <h3>● ✍️Global Posts:</h3>
                        <p>Take a virtual trip around the world without leaving your home! Explore the customs and cultures of different countries, experiencing the charm of global culture. We support word translation, full-text translation, and interactive features such as gift-giving, replies, and likes. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🎁🆚🎁Gift Exchange Gameplay:</h3>
                        <p>Exchange country-specific gifts with users from around the world, sharing the cultural characteristics of both countries and allowing friendships to transcend borders. Show the world your culture. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🤣Chat with Dynamic Emojis:</h3>
                        <p>Dynamic emojis make conversations more fun, adding a touch of magic to your interactions. Add joy to your communication. </p>
                    </li>
                    <li class="e-row">
                        <h3>● # Country Topics Feature:</h3>
                        <p>Not sure what to talk about? No worries! We provide topics and images related to food, architecture, and holidays from over 30 countries, making it easy for you to start a conversation and liven up each chat. </p>
                    </li>
                    <li class="e-row">
                        <p>Join TalkIn and embark on your journey of global language learning and cultural exchange, making learning and socializing even more exciting!</p>
                    </li>
                </ul>
            </div> -->
            <div class="s-slogan" v-html="tranDom && tranDom.slogan">
                <p>Join TalkIn</p>
                <p>Language Learning and Global Social Revolution!</p>
            </div>
            <div class="s-sign">
                <img draggable="false" class="img" src="@/assets/images/store/b1.png" />
            </div>
            <div class="s-desc" v-html="tranDom && tranDom.desc">
                <p>TalkIn is a youthful language learning and social application, and also a platform for connecting with high-quality language partners from around the world. We aim to create a vibrant and interactive language social circle. We don't do language corners; what we offer is a global language social party.</p>
                <p>Do you want to find global language partners to practice speaking with? Do you want to learn about the cultural characteristics of different countries? Do you want to practice foreign language pronunciation? Do you want to find a trustworthy friend in a foreign country? Do you want to showcase the most interesting aspects of your own country's culture? Do you want to make friends while traveling?</p>
                <p>We can help you with all of this, allowing you to meet global language partners and have exciting conversations from the comfort of your home.</p>
                <p>TalkIn makes everything accessible and turns the world into your home!</p>
            </div>
            <div class="s-exhibit">
                <img draggable="false" src="@/assets/images/store/s1.png" class="s-img1" />
                <img draggable="false" src="@/assets/images/store/s2.png" class="s-img2" />
                <img draggable="false" src="@/assets/images/store/s3.png" class="s-img3" />
                <img draggable="false" src="@/assets/images/store/s4.png" class="s-img4" />
                <img draggable="false" src="@/assets/images/store/s5.png" class="s-img5" />
                <img draggable="false" src="@/assets/images/store/s6.png" class="s-img6" />
            </div>
            <div class="exhibit-desc" v-html="tranDom && tranDom.exhibit">
                <ul class="e-list">
                    <li class="e-row">
                        <h3>●🎁 Global Gifts: </h3>
                        <p>TalkIn offers exclusive cultural gifts from over 30 different countries, allowing you to share culture through gifts and build friendships across borders!</p>
                    </li>
                    <li class="e-row">
                        <h3>● 🆓Free Lifetime Translation: </h3>
                        <p>TalkIn provides unlimited, lifetime translation services across the platform, ensuring seamless communication for users around the world. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🧑‍🤝‍🧑Efficient Matching Algorithm:</h3>
                        <p>Our multidimensional information processing technology can quickly match you with your ideal language partner worldwide, enhancing learning efficiency and social experience. </p>
                    </li>
                    <li class="e-row">
                        <h3>● ✍️Global Posts:</h3>
                        <p>Take a virtual trip around the world without leaving your home! Explore the customs and cultures of different countries, experiencing the charm of global culture. We support word translation, full-text translation, and interactive features such as gift-giving, replies, and likes. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🎁🆚🎁Gift Exchange Gameplay:</h3>
                        <p>Exchange country-specific gifts with users from around the world, sharing the cultural characteristics of both countries and allowing friendships to transcend borders. Show the world your culture. </p>
                    </li>
                    <li class="e-row">
                        <h3>● 🤣Chat with Dynamic Emojis:</h3>
                        <p>Dynamic emojis make conversations more fun, adding a touch of magic to your interactions. Add joy to your communication. </p>
                    </li>
                    <li class="e-row">
                        <h3>● # Country Topics Feature:</h3>
                        <p>Not sure what to talk about? No worries! We provide topics and images related to food, architecture, and holidays from over 30 countries, making it easy for you to start a conversation and liven up each chat. </p>
                    </li>
                    <li class="e-row">
                        <p>Join TalkIn and embark on your journey of global language learning and cultural exchange, making learning and socializing even more exciting!</p>
                    </li>
                </ul>
            </div>
            <div class="s-ticket">
                <div class="ticket-card">
                    <div class="t-desc">
                        <img draggable="false" src="@/assets/images/store/t7.png" class="t-bg" />
                    </div>
                    <div class="t-btn">
                        <div class="btn-img" @click="$TurnIosOrAndroid(1)">
                            <img draggable="false" class="img" src="@/assets/images/download-app.png" />
                            <div class="btn1"></div>
                        </div>
                        <div class="btn-img" @click="$TurnIosOrAndroid(0)">
                            <img draggable="false" class="img" src="@/assets/images/download-google.png" />
                            <div class="btn2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BotFooter />
    </div>
</template>
<script>
import FixNav from './FixNav.vue'
import BotFooter from './BotFooter.vue'
export default {
  name: 'ContantPage',
  data () {
    return {
      isMobile: false,
      tranDom: {
        slogan: '',
        desc: '',
        exhibit: ''
      }
    }
  },
  components: {
    FixNav,
    BotFooter
  },
  created () {
    this.initData()
  },
  mounted () {
    if (document.getElementsByClassName('page-home')[0].clientWidth < 800) {
      this.isMobile = true
    }
  },
  methods: {
    initData () {
      const that = this
      // 翻译
      class Trantxtlang {
        constructor (lang) {
          this.lang = lang
          this.jsonData = {
            'store-tran': {
              'zh-Hans': `
                    加入 TalkIn - 语言学习和全球社交革命!
                    TalkIn是一款年轻化语言学习社交应用，也是结交全球优质语伴的平台。我们想打造一个充满活力与互动的语言社交圈。我们不做外语角，我们要提供的是全球语言社交派对。
                    想要找全球语伴陪你练口语？想要了解各国文化特色？想要练习外语口语发音？想要在陌生的国家找到一个值得信赖的朋友？想要展现出自己国家最有趣的文化？想要在旅游地结交朋友？ 
                    统统帮你实现，让你在家就能结识全球语伴，在家畅聊嗨翻天。
                    TalkIn  让一切触手可及、让世界成为你的家！
                    ●🎁全球礼物:
                    TalkIn提供30多个不同国家的专属特色礼物，用礼物分享文化，让友谊跨越国界！
                    ●🆓翻译终身免费:
                    TalkIn无限次数的全板块终身免费翻译服务，确保全球用户能够无障碍沟通。
                    ●👬🏻高效匹配算法:
                    我们的多维度信息处理技术能迅速在全球匹配出你的最佳语伴，提高学习效率和社交体验。
                    ●✍🏻全球贴文:
                    带你不出门也能环游世界！领略世界各地的风土人情，感受全球文化魅力。我们支持点词翻译、全文翻译，并提供互动功能如礼物赠送、回复、点赞。
                    ●🎁🫶🎁礼物交换玩法:
                    与全球用户交换专属于各国的礼物，分享两国文化特色，让友谊超越国界，向世界展示你的文化。
                    ●🥰聊天动态表情:
                    动态表情让聊天更有趣，给你们的互动加入表情的魔法。为交流增添乐趣。
                    ●#️⃣国家话题功能:
                    不知道聊什么？不用担心！我们提供30多个国家的美食、建筑、节日话题图片、文案，帮你轻松开启对话，热络每次聊天。
                    加入TalkIn，开启你的全球语言学习和文化交流之旅，让学习和社交变得更加精彩！
              `,
              'zh-Hant': `
                    加入TalkIn - 語言學習和全球社交革命！
                    TalkIn是一款針對年輕人的語言學習社交應用，也是結交全球優質語伴的平台。我們希望打造一個充滿活力與互動的語言社交圈。我們不做外語角，我們要提供的是全球語言社交派對。
                    想要找全球語伴陪你練習口語？想要了解各國文化特色？想要練習外語口語發音？想要在陌生的國家找到一個值得信賴的朋友？想要展現出自己國家最有趣的文化？想要在旅遊地結交朋友？
                    我們可以幫你實現，讓你在家就能結識全球語伴，在家暢聊嗨翻天。
                    TalkIn 讓一切觸手可及、讓世界成為你的家！
                    ●🎁全球禮物:
                    TalkIn提供30多個不同國家的專屬特色禮物，用禮物分享文化，讓友誼跨越國界！
                    ●🆓翻譯終身免費:
                    TalkIn無限次數的全板塊終身免費翻譯服務，確保全球用戶能夠無障礙溝通。
                    ●👬🏻高效匹配算法:
                    我們的多維度信息處理技術能迅速在全球匹配出你的最佳語伴，提高學習效率和社交體驗。
                    ●✍🏻全球貼文:
                    帶你不出門也能環遊世界！領略世界各地的風土人情，感受全球文化魅力。我們支持點詞翻譯、全文翻譯，並提供互動功能如禮物贈送、回覆、點讚。
                    ●🎁🫶🎁禮物交換玩法:
                    與全球用戶交換專屬於各國的禮物，分享兩國文化特色，讓友誼超越國界，向世界展示你的文化。
                    ●🥰聊天動態表情:
                    動態表情讓聊天更有趣，給你們的互動加入表情的魔法。為交流增添樂趣。
                    ●#️⃣國家話題功能:
                    不知道聊什麼？不用擔心！我們提供30多個國家的美食、建築、節日話題圖片、文案，幫你輕鬆開啟對話，熱絡每次聊天。
                    加入TalkIn，開啟你的全球語言學習和文化交流之旅，讓學習和社交變得更加精彩！
              `,
              en: `
                Join TalkIn - Language Learning and Global Social Revolution!
                TalkIn is a youthful language learning and social application, and also a platform for connecting with high-quality language partners from around the world. We aim to create a vibrant and interactive language social circle. We don't do language corners; what we offer is a global language social party. 
                Do you want to find global language partners to practice speaking with? Do you want to learn about the cultural characteristics of different countries? Do you want to practice foreign language pronunciation? Do you want to find a trustworthy friend in a foreign country? Do you want to showcase the most interesting aspects of your own country's culture? Do you want to make friends while traveling? We can help you with all of this, allowing you to meet global language partners and have exciting 
                conversations from the comfort of your home.
                TalkIn makes everything accessible and turns the world into your home!
                ●🎁Global Gifts:
                TalkIn offers exclusive cultural gifts from over 30 different countries, allowing you to share culture through gifts and build friendships across borders!
                ●🆓Free Lifetime Translation:
                TalkIn provides unlimited, lifetime translation services across the platform, ensuring seamless communication for users around the world.
                ●👬🏻Efficient Matching Algorithm:
                Our multidimensional information processing technology can quickly match you with your ideal language partner worldwide, enhancing learning efficiency and social experience.
                ●✍🏻Global Posts:
                Take a virtual trip around the world without leaving your home! Explore the customs and cultures of different countries, experiencing the charm of global culture. We support word translation, full-text translation, and interactive features such as gift-giving, replies, and likes.
                ●🎁🫶🎁Gift Exchange Gameplay:
                Exchange country-specific gifts with users from around the world, sharing the cultural characteristics of both countries and allowing friendships to transcend borders. Show the world your culture.
                ●🥰Chat with Dynamic Emojis:
                Dynamic emojis make conversations more fun, adding a touch of magic to your interactions. Add joy to your communication.
                ●#️⃣Country Topics Feature:
                Not sure what to talk about? No worries! We provide topics and images related to food, architecture, and holidays from over 30 countries, making it easy for you to start a conversation and liven up each chat.
                Join TalkIn and embark on your journey of global language learning and cultural exchange, making learning and socializing even more exciting!
              `,
              ja: `
                TalkInに参加して - 言語学習とグローバルな社交革命に挑戦しよう！
                TalkInは、若者向けの言語学習と社交のアプリケーションであり、世界中から質の高い言語パートナーをつなぐプラットフォームです。私たちの目標は、活気に満ちた相互作用のある言語社交の輪を作ることです。私たちは言語コーナーを行いません；私たちが提供するのは、グローバルな言語社交パーティーです。
                世界中の言語パートナーを見つけて会話の練習をしたいですか？異なる国々の文化の特徴を学びたいですか？外国語の発音を練習したいですか？外国で信頼できる友人を見つけたいですか？自国の文化の面白い部分を見せたいですか？旅行中に友達を作りたいですか？
                私たちはこれらすべてを実現するお手伝いをします。自宅にいながら、世界中の言語パートナーに会い、心躍る会話を楽しむことができます。
                TalkInは、すべてを手の届くところにし、世界をあなたの家にします！
                ●🎁グローバルギフト：
                TalkInは、30カ国以上の独自の文化的な贈り物を提供し、贈り物を通じて文化を共有し、国境を越えて友情を築くことができます！
                ●🆓無料の終身翻訳：
                TalkInは、プラットフォーム全体で無制限の終身翻訳サービスを提供し、世界中のユーザー間でのスムーズなコミュニケーションを保証します。
                ●👬🏻効率的なマッチングアルゴリズム：
                私たちの多次元情報処理技術により、理想的な言語パートナーと迅速にマッチングし、学習効率と社交体験を高めます。
                ●✍🏻グローバルポスト：
                家を出ることなく、世界一周の旅ができます！異なる国々の習慣や文化を探索し、グローバルな文化の魅力を体験します。私たちは、単語翻訳、全文翻訳、贈り物の交換、返信、いいねなどのインタラクティブな機能をサポートしています。
                ●🎁🫶🎁ギフト交換ゲームプレイ：
                世界中のユーザーと国特有のギフトを交換し、互いの国の文化的特徴を共有し、友情を国境を越えて広げます。あなたの文化を世界に見せてください。
                ●🥰ダイナミック絵文字でチャット：
                ダイナミックな絵文字で会話をより楽しくし、あなたの交流に魔法のタッチを加えます。コミュニケーションに喜びを加えましょう。
                ●#️⃣国のトピック機能：
                何について話すかわからない？心配無用！30カ国以上の食べ物、建築、祝日に関連するトピックと画像を提供し、会話を始めやすく、チャットを活発にします。
                TalkInに参加して、世界の言語学習と文化交流の旅を始めましょう。学習と社交をもっとエキサイティングに！
              `,
              ko: `
                TalkIn 가입하기 - 언어 학습 및 글로벌 소셜 혁명!
                TalkIn은 젊은 세대를 위한 언어 학습 소셜 앱이자 전 세계 우수한 언어 파트너를 만날 수 있는 플랫폼입니다. 우리는 활기차고 상호 작용이 풍부한 언어 소셜 네트워크를 만들고자 합니다. 우리는 단순한 외국어 모임이 아니라 글로벌 언어 소셜 파티를 제공합니다.
                전 세계 언어 파트너와 함께 언어 연습을 하고 싶으신가요? 다양한 나라의 문화 특성을 알고 싶으신가요? 외국어 발음을 연습하고 싶으신가요? 낯선 나라에서 신뢰할 수 있는 친구를 찾고 싶으신가요? 자국의 흥미로운 문화를 세계에 보여주고 싶으신가요?
                여행지에서 친구를 만들고 싶으신가요?모든 것을 도와드립니다. 집에서 전 세계 언어 파트너를 만나고, 흥미진진한 대화를 즐길 수 있습니다.
                TalkIn은 모든 것을 손쉽게 하여, 세상을 당신의 집으로 만듭니다!
                ●🎁 글로벌 선물:
                TalkIn은 30개 이상의 나라에서 온 특별한 선물을 제공하여, 선물을 통해 문화를 공유하고 국경을 넘는 우정을 쌓을 수 있습니다!
                ●🆓 평생 무료 번역:
                TalkIn은 플랫폼 전체에서 무제한 평생 무료 번역 서비스를 제공하여, 전 세계 사용자 간의 원활한 의사소통을 보장합니다.
                ● 👬🏻고효율 매칭 알고리즘:
                다차원 정보 처리 기술을 통해 전 세계에서 이상적인 언어 파트너를 빠르게 매칭하여 학습 효율과 소셜 경험을 향상시킵니다.
                ● ✍🏻글로벌 게시물:
                집을 나가지 않고도 세계 일주! 전 세계의 풍습과 문화를 경험하고, 글로벌 문화의 매력을 느낄 수 있습니다. 단어 번역, 전문 번역 및 선물하기, 답글, 좋아요 등의 상호 작용 기능을 지원합니다.
                ● 🎁🫶🎁선물 교환 게임:
                전 세계 사용자와 각국의 특색 있는 선물을 교환하여, 양국의 문화적 특성을 공유하고 국경을 초월한 우정을 나누세요. 세계에 당신의 문화를 보여주세요.
                ● 🥰동적 이모티콘 채팅:
                동적 이모티콘으로 채팅을 더 재미있게 하고, 상호 작용에 마법 같은 터치를 더합니다. 의사소통에 즐거움을 더하세요.
                ● #️⃣국가별 주제 기능:
                무엇에 대해 이야기할지 모르시겠나요? 걱정 마세요! 30개국 이상의 음식, 건축, 휴일에 관련된 주제와 이미지, 문구를 제공하여 쉽게 대화를 시작하고, 채팅을 활기차게 할 수 있습니다.
                TalkIn에 가입하고, 전 세계 언어 학습 및 문화 교류 여정을 시작하세요. 학습과 소셜 활동을 더욱 흥미진진하게!
              `,
              es: `
                ¡Únete a TalkIn - La revolución del aprendizaje de idiomas y la socialización global!
                TalkIn es una aplicación social de aprendizaje de idiomas para jóvenes, también es una plataforma para hacer amigos globales de alta calidad. Queremos crear un círculo social de idiomas lleno de vitalidad e interacción. No somos una esquina de idiomas extranjeros, lo que ofrecemos es una fiesta social global de idiomas.
                ¿Quieres encontrar compañeros globales para practicar tu habla? ¿Quieres conocer las características culturales de diferentes países? ¿Quieres practicar la pronunciación en un idioma extranjero? ¿Quieres encontrar un amigo confiable en un país extranjero? ¿Quieres mostrar la cultura más interesante de tu país? ¿Quieres hacer amigos en lugares turísticos?
                Te ayudamos a lograr todo esto, para que puedas conocer compañeros globales desde casa y tener conversaciones emocionantes.
                TalkIn hace que todo sea accesible y convierte al mundo en tu hogar.
                ●🎁 Regalos globales:
                TalkIn ofrece más de 30 regalos exclusivos de diferentes países, comparte cultura con regalos y deja que la amistad cruce fronteras.
                ● 🆓Traducción gratuita de por vida:
                TalkIn ofrece un servicio de traducción gratuita e ilimitada en todas sus secciones, asegurando que los usuarios globales puedan comunicarse sin barreras.
                ● 👬🏻Algoritmo de emparejamiento eficiente:
                Nuestra tecnología de procesamiento de información multidimensional puede emparejarte rápidamente con tu mejor compañero de idiomas en todo el mundo, mejorando la eficiencia del aprendizaje y la experiencia social.
                ● ✍🏻 Publicaciones globales:
                ¡Te llevamos a dar la vuelta al mundo sin salir de casa! Experimenta las costumbres y culturas de diferentes lugares, siente el encanto cultural global. Apoyamos la traducción de palabras, traducción completa de textos y ofrecemos funciones interactivas como regalos, respuestas y 'me gusta'.
                ● 🎁🫶🎁Intercambio de regalos:
                Intercambia regalos exclusivos de diferentes países con usuarios globales, comparte las características culturales de ambos países, dejando que la amistad trascienda fronteras y mostrando tu cultura al mundo.
                ● 🥰Emoticonos dinámicos para chat:
                Los emoticonos dinámicos hacen que chatear sea más divertido, agregando magia de expresiones a tu interacción. Añade diversión a la comunicación.
                ● #️⃣Función de temas por país:
                ¿No sabes de qué hablar? ¡No te preocupes! Ofrecemos imágenes y textos sobre comida, arquitectura y festivales de más de 30 países, ayudándote a iniciar conversaciones fácilmente y animar cada chat.
                Únete a TalkIn, comienza tu viaje de aprendizaje de idiomasy intercambio cultural global, ¡haciendo que el aprendizaje y la socialización sean aún más emocionantes！
              `,
              fr: `
                Rejoignez TalkIn - La révolution de l'apprentissage des langues et de la socialisation mondiale !
                TalkIn est une application sociale et d'apprentissage des langues pour les jeunes, et une plateforme pour se faire des amis de qualité dans le monde entier. Nous souhaitons créer un cercle social linguistique plein de vitalité et d'interaction. Nous ne faisons pas juste un coin des langues étrangères, nous offrons une fête sociale linguistique mondiale.
                Vous voulez trouver des partenaires linguistiques mondiaux pour pratiquer votre expression orale ? Vous voulez découvrir les particularités culturelles des différents pays ? Vous voulez pratiquer la prononciation d'une langue étrangère ? Vous voulez trouver un ami digne de confiance dans un pays étranger ? Vous voulez montrer la culture la plus intéressante de votre pays ? Vous voulez vous faire des amis dans un lieu de vacances ?
                Nous vous aidons à réaliser tout cela, vous permettant de rencontrer des partenaires linguistiques mondiaux depuis chez vous et de discuter de manière enthousiaste.
                TalkIn rend tout accessible et fait du monde votre foyer !
                ● 🎁Cadeaux mondiaux :
                TalkIn propose plus de 30 cadeaux spéciaux de différents pays, partagez la culture avec des cadeaux, et laissez l'amitié transcender les frontières !
                ●🆓 Traduction gratuite à vie :
                TalkIn offre un service de traduction gratuit à vie et illimité pour toutes ses sections, assurant une communication sans obstacle pour les utilisateurs du monde entier.
                ●👬🏻Algorithme de correspondance efficace :
                Notre technologie de traitement d'informations multidimensionnelles peut rapidement trouver le meilleur partenaire linguistique pour vous dans le monde entier, améliorant l'efficacité de l'apprentissage et l'expérience sociale.
                ●  ✍🏻Publications mondiales :
                Voyagez autour du monde sans quitter votre maison ! Découvrez les coutumes et la culture de différents pays. Nous soutenons la traduction de mots, la traduction de textes complets, et offrons des fonctionnalités interactives telles que l'envoi de cadeaux, les réponses et les likes.
                ●🎁🫶🎁 Échange de cadeaux :
                Échangez des cadeaux spécifiques à chaque pays avec des utilisateurs du monde entier, partagez les caractéristiques culturelles des deux pays, et laissez l'amitié dépasser les frontières pour montrer votre culture au monde.
                ● 🥰Émoticônes dynamiques pour le chat :
                Les émoticônes dynamiques rendent la conversation plus amusante, ajoutant de la magie émotionnelle à vos interactions et rendant la communication plus agréable.
                ●#️⃣ Fonction de sujets par pays :
                Vous ne savez pas de quoi parler ? Ne vous inquiétez pas ! Nous fournissons des images et des textes de sujets sur la nourriture, l'architecture, les festivals de plus de 30 pays pour vous aider à lancer facilement des conversations et à rendre chaque chat animé.
                Rejoignez TalkIn et commencez votre voyage d'apprentissage des langues et d'échange culturel mondial, rendant l'apprentissage et la socialisation encore plus passionnants !
              `,
              de: `
                Rejoignez TalkIn - La révolution de l'apprentissage des langues et de la socialisation mondiale !
                TalkIn est une application sociale et d'apprentissage des langues pour les jeunes, et une plateforme pour se faire des amis de qualité dans le monde entier. Nous souhaitons créer un cercle social linguistique plein de vitalité et d'interaction. Nous ne faisons pas juste un coin des langues étrangères, nous offrons une fête sociale linguistique mondiale.
                Vous voulez trouver des partenaires linguistiques mondiaux pour pratiquer votre expression orale ? Vous voulez découvrir les particularités culturelles des différents pays ? Vous voulez pratiquer la prononciation d'une langue étrangère ? Vous voulez trouver un ami digne de confiance dans un pays étranger ? Vous voulez montrer la culture la plus intéressante de votre pays ? Vous voulez vous faire des amis dans un lieu de vacances ?
                Nous vous aidons à réaliser tout cela, vous permettant de rencontrer des partenaires linguistiques mondiaux depuis chez vous et de discuter de manière enthousiaste.
                TalkIn rend tout accessible et fait du monde votre foyer !
                ●🎁 Cadeaux mondiaux :
                TalkIn propose plus de 30 cadeaux spéciaux de différents pays, partagez la culture avec des cadeaux, et laissez l'amitié transcender les frontières !
                ● 🆓Traduction gratuite à vie :
                TalkIn offre un service de traduction gratuit à vie et illimité pour toutes ses sections, assurant une communication sans obstacle pour les utilisateurs du monde entier.
                ●👬🏻 Algorithme de correspondance efficace :
                Notre technologie de traitement d'informations multidimensionnelles peut rapidement trouver le meilleur partenaire linguistique pour vous dans le monde entier, améliorant l'efficacité de l'apprentissage et l'expérience sociale.
                ●✍🏻 Publications mondiales :
                Voyagez autour du monde sans quitter votre maison ! Découvrez les coutumes et la culture de différents pays. Nous soutenons la traduction de mots, la traduction de textes complets, et offrons des fonctionnalités interactives telles que l'envoi de cadeaux, les réponses et les likes.
                ●🎁🫶🎁  Échange de cadeaux :
                Échangez des cadeaux spécifiques à chaque pays avec des utilisateurs du monde entier, partagez les caractéristiques culturelles des deux pays, et laissez l'amitié dépasser les frontières pour montrer votre culture au monde.
                ●🥰 Émoticônes dynamiques pour le chat :
                Les émoticônes dynamiques rendent la conversation plus amusante, ajoutant de la magie émotionnelle à vos interactions et rendant la communication plus agréable.
                ● #️⃣Fonction de sujets par pays :
                Vous ne savez pas de quoi parler ? Ne vous inquiétez pas ! Nous fournissons des images et des textes de sujets sur la nourriture, l'architecture, les festivals de plus de 30 pays pour vous aider à lancer facilement des conversations et à rendre chaque chat animé.
                Rejoignez TalkIn et commencez votre voyage d'apprentissage des langues et d'échange culturel mondial, rendant l'apprentissage et la socialisation encore plus passionnants !
              `,
              it: `
                Unisciti a TalkIn - la rivoluzione dell'apprendimento linguistico e della socializzazione globale!
                TalkIn è un'app sociale per l'apprendimento delle lingue rivolta ai giovani, ed è anche una piattaforma per incontrare partner linguistici di qualità in tutto il mondo. Vogliamo creare un circolo linguistico sociale pieno di vitalità e interazione. Non siamo un angolo di lingua straniera, ciò che offriamo è una festa sociale globale delle lingue.
                Vuoi trovare partner globali per praticare il parlato? Vuoi conoscere le caratteristiche culturali dei vari paesi? Vuoi esercitarti nella pronuncia delle lingue straniere? Vuoi trovare un amico di fiducia in un paese straniero? Vuoi mostrare la cultura più interessante del tuo paese? Vuoi fare amicizia nei luoghi turistici?
                Ti aiutiamo a realizzare tutto questo, permettendoti di incontrare partner linguistici globali da casa e di chattare in modo entusiasmante.
                TalkIn rende tutto a portata di mano e trasforma il mondo in casa tua!
                ●🎁 Regali globali:
                TalkIn offre oltre 30 regali esclusivi di diversi paesi, condividi la cultura con i regali, lascia che l'amicizia oltrepassi i confini nazionali!
                ● 🆓Traduzione gratuita a vita:
                TalkIn offre un servizio di traduzione gratuita illimitata a vita in tutti i settori, garantendo una comunicazione senza barriere per gli utenti di tutto il mondo.
                ● 👬🏻Algoritmo di abbinamento efficiente:
                La nostra tecnologia di elaborazione delle informazioni multidimensionale può abbinarti rapidamente al miglior partner linguistico globale, migliorando l'efficienza dell'apprendimento e l'esperienza sociale.
                ● ✍🏻Post globali:
                Ti portiamo a viaggiare in giro per il mondo senza uscire di casa! Esplora le usanze locali di tutto il mondo, senti il fascino della cultura globale. Supportiamo la traduzione di parole, la traduzione di testi completi e offriamo funzioni interattive come doni, risposte, 'mi piace'.
                ● 🎁🫶🎁 Scambio di regali:
                Scambia regali esclusivi dei vari paesi con utenti globali, condividi le caratteristiche culturali di due paesi, lascia che l'amicizia superi i confini nazionali e mostra la tua cultura al mondo.
                ● 🥰Emoticon dinamiche per chat:
                Le emoticon dinamiche rendono la chat più divertente, aggiungendo magia espressiva alle vostre interazioni. Aggiungi divertimento alla comunicazione.
                ●#️⃣ Funzione di argomenti nazionali:
                Non sai di cosa parlare? Non preoccuparti! Forniamo immagini e copioni su cibo, architettura, festival di oltre 30 paesi, per aiutarti a iniziare facilmente le conversazioni e rendere ogni chat più vivace.
                Unisciti a TalkIn, inizia il tuo viaggio di apprendimento della lingua globale e di scambio culturale, rendendo l'apprendimento e la socializzazione ancora più emozionanti!
              `,
              pt: `
                Junte-se ao TalkIn - Revolução na aprendizagem de idiomas e socialização global!
                O TalkIn é um aplicativo social de aprendizagem de idiomas voltado para o público jovem, e também uma plataforma para fazer amigos de alta qualidade em todo o mundo. Queremos criar um círculo social de idiomas cheio de energia e interação. Não queremos ser apenas um canto para aprender línguas estrangeiras, queremos fornecer uma festa social global de idiomas.
                Quer encontrar amigos de línguas de todo o mundo para praticar a fala? Quer conhecer as características culturais de diferentes países? Quer praticar a pronúncia de um idioma estrangeiro? Quer encontrar um amigo confiável em um país estrangeiro? Quer mostrar a cultura mais interessante do seu país? Quer fazer amigos em locais turísticos?
                Podemos realizar tudo isso para você, permitindo que você faça amizades globais sem sair de casa e converse à vontade.
                O TalkIn torna tudo acessível e transforma o mundo na sua casa!
                ● 🎁Presentes Globais:
                TalkIn oferece mais de 30 presentes exclusivos de diferentes países, compartilhando culturas com presentes e transcendendo fronteiras de amizade!
                ● 🆓Tradução gratuita para a vida:
                TalkIn oferece um serviço de tradução gratuito e ilimitado para todos os seus usuários globais, garantindo comunicação sem barreiras.
                ● 👬🏻Algoritmo de correspondência eficiente:
                Nossa tecnologia de processamento de informações multidimensionais pode rapidamente encontrar o melhor parceiro de idioma para você em todo o mundo, melhorando a eficiência de aprendizado e a experiência social.
                ● ✍🏻Postagens globais:
                Leve você a viajar pelo mundo sem sair de casa! Experimente a cultura e os costumes locais de diferentes lugares do mundo. Oferecemos tradução de palavras individuais, tradução de texto completo e funcionalidades interativas como envio de presentes, respostas e curtidas.
                ● 🎁🫶🎁Intercâmbio de presentes:
                Troque presentes exclusivos de diferentes países com usuários globais, compartilhando características culturais de dois países e levando a amizade além das fronteiras, mostrando sua cultura para o mundo.
                ● 🥰Emojis dinâmicos para chat:
                Emojis dinâmicos tornam a conversa mais divertida, adicionando magia expressiva à sua interação. Adicione diversão à comunicação.
                ●#️⃣ Função de tópicos nacionais:
                Não sabe sobre o que conversar? Não se preocupe! Fornecemos fotos e textos sobre comidas, arquiteturas e festivais de mais de 30 países, ajudando você a iniciar conversas facilmente e animar cada bate-papo.
                Junte-se ao TalkIn e inicie sua jornada de aprendizado de idiomas e intercâmbio cultural global, tornando o aprendizado e a socialização ainda mais emocionantes!
              `,
              ru: `
                    Присоединяйтесь к TalkIn - революции в изучении языков и глобальном общении!
                    TalkIn - это молодежное приложение для изучения языков и социальных сетей, а также платформа для знакомства с качественными языковыми партнерами со всего мира. Мы хотим создать динамичное и взаимодействующее языковое общественное пространство. Мы не уголок иностранного языка, мы предлагаем глобальную языковую социальную вечеринку.
                    Хотите найти глобального партнера для практики разговорного языка? Хотите узнать о культурных особенностях разных стран? Хотите практиковать произношение иностранного языка? Хотите найти надежного друга в незнакомой стране? Хотите показать самые интересные культурные особенности своей страны? Хотите завести друзей в месте отдыха?
                    Мы поможем вам во всем этом, позволяя вам знакомиться с глобальными языковыми партнерами прямо дома и весело общаться.
                    TalkIn делает все доступным и превращает мир в ваш дом!
                    ●🎁 Глобальные подарки:
                    TalkIn предлагает более 30 различных национальных подарков, позволяя делиться культурой с помощью подарков и преодолевать границы дружбы!
                    ●🆓 Бесплатный перевод на всю жизнь:
                    TalkIn предлагает неограниченное количество бесплатных переводов для всех пользователей на всю жизнь, обеспечивая беспрепятственное общение между пользователями со всего мира.
                    ● 👬🏻Эффективный алгоритм подбора:
                    Наша многомерная технология обработки информации быстро подбирает для вас лучшего языкового партнера со всего мира, повышая эффективность обучения и социальный опыт.
                    ● ✍🏻Глобальные публикации:
                    Путешествуйте по миру не выходя из дома! Ощутите культуру и обычаи разных стран, почувствуйте привлекательность глобальной культуры. Мы поддерживаем перевод отдельных слов и целых текстов, а также предлагаем интерактивные функции, такие как подарки, ответы, лайки.
                    ● 🎁🫶🎁Обмен подарками:
                    Обменивайтесь национальными подарками с пользователями со всего мира, делитесь культурными особенностями двух стран, позволяя дружбе пересекать границы и демонстрируя свою культуру миру.
                    ●🥰 Динамичные эмоции в чате:
                    Динамичные эмоции делают чат более интересным, добавляя ваши взаимод
                    ействия магическими эмоциями. Добавьте веселья в общение.
                    ●#️⃣ Функция тематических разговоров о странах:
                    Не знаете, о чем говорить? Не волнуйтесь! Мы предлагаем тематические изображения и тексты о еде, архитектуре, праздниках более чем из 30 стран, помогая вам легко начать разговор и сделать каждый чат более живым.
                    Присоединяйтесь к TalkIn, начните свое путешествие по изучению языков и культурного обмена по всему миру, сделайте обучение и социальное взаимодействие еще более захватывающими!
              `,
              tr: `
                TalkIn'e Katılın - Dil Öğrenimi ve Küresel Sosyal Devrim!
                TalkIn, gençlere yönelik bir dil öğrenme ve sosyal uygulamadır ve aynı zamanda dünya çapında yüksek kaliteli dil partnerleriyle bağlantı kurma platformudur. Canlı ve etkileşimli bir dil sosyal çevresi oluşturmayı hedefliyoruz. Dil köşeleri yapmıyoruz; sunduğumuz şey küresel bir dil sosyal partisidir.
                Dünya çapında dil partnerleri bulup konuşma pratiği yapmak mı istiyorsunuz? Farklı ülkelerin kültürel özelliklerini öğrenmek mi istiyorsunuz? Yabancı dil telaffuz pratiği yapmak mı istiyorsunuz? Yabancı bir ülkede güvenilir bir arkadaş mı arıyorsunuz? Kendi ülkenizin en ilginç kültür yönlerini sergilemek mi istiyorsunuz? Seyahat ederken arkadaşlar edinmek mi istiyorsunuz? 
                Bunların hepsinde size yardımcı olabiliriz, dünya çapında dil partnerleriyle tanışıp evinizin konforundan heyecan verici sohbetler yapmanızı sağlarız.
                TalkIn her şeyi erişilebilir kılar ve dünyayı eviniz haline getirir!
                ●🎁Küresel Hediyeler:
                TalkIn, 30'dan fazla farklı ülkeden özel kültürel hediyeler sunar, hediyeler aracılığıyla kültür paylaşmanıza ve sınırlar ötesi dostluklar kurmanıza olanak tanır!
                ●🆓Ömür Boyu Ücretsiz Çeviri:
                TalkIn, dünya çapındaki kullanıcılar için platform genelinde sınırsız ve ömür boyu çeviri hizmetleri sağlayarak sorunsuz iletişim kurulmasını sağlar.
                ●👬🏻Etkili Eşleştirme Algoritması:
                Çok boyutlu bilgi işleme teknolojimizle dünya çapında ideal dil partnerinizi hızla eşleştirebilir, öğrenme verimliliğinizi ve sosyal deneyiminizi artırabiliriz.
                ●✍🏻Küresel Gönderiler:
                Evden çıkmadan dünyayı gezebilirsiniz! Farklı ülkelerin gelenek ve kültürlerini keşfedin, küresel kültürün cazibesini deneyimleyin. Kelime çevirisi, tam metin çeviri desteği ve hediye gönderme, yanıtlama, beğenme gibi etkileşim özellikleri sunuyoruz.
                ●🎁🫶🎁Hediye Değişim Oyunu: 
                Dünya çapındaki kullanıcılarla ülkelere özgü hediyeleri değiştirin, her iki ülkenin kültürel özelliklerini paylaşın, dostlukların sınırları aşmasını sağlayın. Kültürünüzü dünyaya gösterin.
                ●🥰Dinamik Emojilerle Sohbet:
                Dinamik emojiler, sohbetleri daha eğlenceli hale getirir, etkileşimlerinize sihirli bir dokunuş ekler. İletişiminize neşe katar.
                ●#️⃣Ülke Konuları Özelliği:
                Ne konuşacağınızdan emin değil misiniz? Endişelenmeyin! 30'dan fazla ülkenin yemek, mimari, tatil konularıyla ilgili resimler ve bilgiler sunuyoruz, konuşmaya başlamanızı ve her sohbeti canlandırmanızı kolaylaştırıyoruz.
                TalkIn'e katılın ve küresel dil öğrenme ve kültürel değişim yolculuğunuza başlayın, öğrenmeyi ve sosyalleşmeyi daha da heyecan verici hale getirin!
              `,
              ar: `
                انضم إلى TalkIn - تعلم اللغة والثورة الاجتماعية العالمية!
                TalkIn هو تطبيق تعلم لغات شبابي واجتماعي، وأيضاً منصة للتواصل مع شركاء لغويين ذوي جودة عالية من جميع أنحاء العالم. نحن نهدف إلى إنشاء دائرة اجتماعية لغوية نابضة بالحياة وتفاعلية. لا نقوم بإنشاء زوايا لغوية؛ ما نقدمه هو حفل اجتماعي لغوي عالمي.
                هل ترغب في إيجاد شركاء لغويين عالميين لممارسة الكلام معهم؟ هل تريد التعرف على الخصائص الثقافية لمختلف البلدان؟ هل تريد ممارسة نطق اللغة الأجنبية؟ هل ترغب في العثور على صديق موثوق في بلد أجنبي؟ هل تريد عرض أكثر جوانب ثقافة بلدك إثارة للاهتمام؟ هل تريد تكوين صداقات أثناء السفر؟ 
                يمكننا مساعدتك في كل هذا، مما يتيح لك التعرف على شركاء لغويين عالميين وإجراء محادثات مثيرة من راحة منزلك.
                يجعل TalkIn كل شيء في متناول يدك ويحول العالم إلى منزلك!
                الهدايا العالمي🎁ة:
                يقدم TalkIn هدايا ثقافية حصرية من أكثر من 30 بلداً مختلفاً، مما يتيح لك مشاركة الثقافة من خلال الهدايا وبناء الصداقات عبر الحدود!
                الترجمة مدى الحياة مجان🆓اً:
                يوفر TalkIn خدمات ترجمة غير محدودة مدى الحياة عبر المنصة، لضمان التواصل السلس للمستخدمين حول العالم.
                خوارزمية مطابقة فعالة👬🏻:
                يمكن لتقنية معالجة المعلومات متعددة الأبعاد لدينا أن تطابقك بسرعة مع شريكك اللغوي المثالي في جميع أنحاء العالم، مما يعزز كفاءة التعلم والتجربة الاجتماعية.
                المشاركات العالمي✍🏻ة:
                قم برحلة افتراضية حول العالم دون مغادرة منزلك! استكشف عادات وثقافات مختلف البلدان، وتجربة سحر الثقافة العالمية. ندعم ترجمة الكلمات، ترجمة النصوص الكاملة، وميزات تفاعلية مثل إعطاء الهدايا، الردود، والإعجابات.
                لعبة تبادل الهداي🎁🫶🎁ا:
                تبادل الهدايا الخاصة بكل بلد مع مستخدمين من جميع أنحاء العالم، مشاركة الخصائص الثقافية لكلا البلدين والسماح للصداقات بتجاوز الحدود. عرض ثقافتك على العالم.
                الدردشة بالرموز التعبيرية الحيوي🥰ة:
                تجعل الرموز التعبيرية الحيوية المحادثات أكثر متعة، وتضيف لمسة سحرية لتفاعلاتك. أضف الفرح إلى تواصلك.
                ميزة مواضيع البلدان#️⃣:
                غير متأكد مما تتحدث عنه؟ لا داعي للقلق! نوفر مواضيع وصور متعلقة بالطعام، والعمارة، والأعياد من أكثر من 30 بلداً، مما يسهل عليك بدء المحادثة وإحياء كل دردشة.
                انضم إلى TalkIn وابدأ رحلتك في تعلم اللغات العالمية والتبادل الثقافي، مما يجعل التعلم والتواصل الاجتماعي أكثر إثارة!
              `,
              id: `
                Gabung dengan TalkIn - Pembelajaran Bahasa dan Revolusi Sosial Global!
                TalkIn adalah aplikasi pembelajaran bahasa dan sosial yang segar, dan juga platform untuk terhubung dengan mitra bahasa berkualitas tinggi dari seluruh dunia. Kami bertujuan menciptakan lingkaran sosial bahasa yang dinamis dan interaktif. Kami tidak hanya menyediakan sudut bahasa; yang kami tawarkan adalah pesta sosial bahasa global.
                Apakah Anda ingin menemukan mitra bahasa global untuk berlatih berbicara? Apakah Anda ingin mempelajari karakteristik budaya berbagai negara? Apakah Anda ingin berlatih pelafalan bahasa asing? Apakah Anda ingin menemukan teman terpercaya di negara asing? Apakah Anda ingin menampilkan aspek paling menarik dari budaya negara Anda sendiri? Apakah Anda ingin berteman saat bepergian? 
                Kami dapat membantu Anda dengan semua ini, memungkinkan Anda untuk bertemu mitra bahasa global dan memiliki percakapan yang menarik dari kenyamanan rumah Anda.
                TalkIn membuat segalanya mudah diakses dan mengubah dunia menjadi rumah Anda!
                ●🎁Hadiah Global:
                TalkIn menawarkan hadiah budaya eksklusif dari lebih dari 30 negara berbeda, memungkinkan Anda untuk berbagi budaya melalui hadiah dan membangun persahabatan lintas batas!
                ●🆓Terjemahan Seumur Hidup Gratis:
                TalkIn menyediakan layanan terjemahan seumur hidup tak terbatas di seluruh platform, memastikan komunikasi tanpa hambatan bagi pengguna di seluruh dunia.
                ●👬🏻Algoritma Pencocokan Efisien:
                Teknologi pengolahan informasi multidimensi kami dapat dengan cepat mencocokkan Anda dengan mitra bahasa ideal Anda di seluruh dunia, meningkatkan efisiensi pembelajaran dan pengalaman sosial.
                ●✍🏻Postingan Global:
                Jelajahi dunia dari rumah Anda! Jelajahi adat istiadat dan budaya berbagai negara, merasakan pesona budaya global. Kami mendukung terjemahan kata, terjemahan teks penuh, dan fitur interaktif seperti pemberian hadiah, balasan, dan suka.
                ●🎁🫶🎁Permainan Pertukaran Hadiah:
                Tukarkan hadiah khas negara dengan pengguna dari seluruh dunia, berbagi karakteristik budaya kedua negara dan memungkinkan persahabatan melintasi batas. Tunjukkan budaya Anda kepada dunia.
                ●🥰Obrolan dengan Emoji Dinamis:
                Emoji dinamis membuat percakapan lebih menyenangkan, menambahkan sentuhan sihir pada interaksi Anda. Tambahkan kegembiraan pada komunikasi Anda.
                ●#️⃣Fitur Topik Negara:
                Tidak yakin tentang apa yang harus dibicarakan? Tidak masalah! Kami menyediakan topik dan gambar terkait makanan, arsitektur, dan hari libur dari lebih dari 30 negara, memudahkan Anda untuk memulai percakapan dan menghidupkan setiap obrolan.
                Bergabunglah dengan TalkIn dan mulailah perjalanan Anda dalam pembelajaran bahasa global dan pertukaran budaya, membuat pembelajaran dan bersosialisasi menjadi lebih menarik!
              `,
              vi: `
                Tham gia TalkIn - Học Ngôn Ngữ và Cách Mạng Xã Hội Toàn Cầu!
                TalkIn là một ứng dụng học ngôn ngữ và xã hội trẻ trung, đồng thời cũng là nền tảng kết nối với các đối tác ngôn ngữ chất lượng cao trên toàn thế giới. Chúng tôi hướng đến việc tạo ra một vòng tròn xã hội ngôn ngữ sống động và tương tác. Chúng tôi không chỉ làm góc học ngôn ngữ; điều chúng tôi mang lại là một bữa tiệc xã hội ngôn ngữ toàn cầu.
                Bạn muốn tìm đối tác ngôn ngữ toàn cầu để luyện nói không? Bạn muốn tìm hiểu về đặc điểm văn hóa của các quốc gia khác nhau không? Bạn muốn luyện phát âm ngôn ngữ nước ngoài không? Bạn muốn tìm một người bạn đáng tin cậy ở nước ngoài không? Bạn muốn giới thiệu những điểm thú vị nhất trong văn hóa quốc gia của mình không? Bạn muốn kết bạn khi đi du lịch không? 
                Chúng tôi có thể giúp bạn tất cả những điều này, cho phép bạn gặp gỡ đối tác ngôn ngữ toàn cầu và có những cuộc trò chuyện thú vị ngay từ nhà bạn.
                TalkIn giúp mọi thứ trở nên dễ tiếp cận và biến thế giới thành ngôi nhà của bạn!
                ●🎁Quà Tặng Toàn Cầu:
                TalkIn cung cấp quà tặng văn hóa độc quyền từ hơn 30 quốc gia khác nhau, cho phép bạn chia sẻ văn hóa qua quà tặng và xây dựng tình bạn vượt qua biên giới!
                ●🆓Dịch Vụ Dịch Thuật Miễn Phí Trọn Đời:
                TalkIn cung cấp dịch vụ dịch thuật không giới hạn, trọn đời trên toàn bộ nền tảng, đảm bảo giao tiếp liền mạch cho người dùng trên toàn thế giới.
                ●👬🏻Thuật Toán Ghép Nối Hiệu Quả:
                Công nghệ xử lý thông tin đa chiều của chúng tôi có thể nhanh chóng tìm ra đối tác ngôn ngữ lý tưởng cho bạn trên toàn thế giới, nâng cao hiệu quả học tập và trải nghiệm xã hội.
                ●✍🏻Bài Đăng Toàn Cầu:
                Hãy thực hiện chuyến đi vòng quanh thế giới ngay tại nhà bạn! Khám phá phong tục và văn hóa của các quốc gia khác nhau, trải nghiệm sức hút của văn hóa toàn cầu. Chúng tôi hỗ trợ dịch từng từ, dịch toàn bản văn, và các tính năng tương tác như tặng quà, trả lời, và thích.
                ●🎁🫶🎁Gameplay Trao Đổi Quà Tặng:
                Trao đổi quà tặng đặc trưng của từng quốc gia với người dùng trên toàn thế giới, chia sẻ đặc điểm văn hóa của cả hai quốc gia và cho phép tình bạn vượt qua biên giới. Hiển thị văn hóa của bạn với thế giới.
                ●🥰Trò Chuyện với Biểu Tượng Cảm Xúc Động:
                Biểu tượng cảm xúc động làm cho cuộc trò chuyện trở nên thú vị hơn, thêm một chút phép màu vào tương tác của bạn. Thêm niềm vui vào giao tiếp của bạn.
                ●#️⃣Tính Năng Chủ Đề Quốc Gia:
                Không chắc chắn nên nói về điều gì? Đừng lo! Chúng tôi cung cấp các chủ đề và hình ảnh liên quan đến ẩm thực, kiến trúc và lễ hội của hơn 30 quốc gia, giúp bạn dễ dàng bắt đầu cuộc trò chuyện và làm cho mỗi cuộc chat trở nên sống động.
                Tham gia TalkIn và bắt đầu hành trình học ngôn ngữ và giao lưu văn hóa toàn cầu của bạn, làm cho việc học và giao tiếp trở nên thú vị hơn!
              `,
              th: `
                เข้าร่วม TalkIn - การเรียนภาษาและการปฏิวัติทางสังคมระดับโลก!
                TalkIn เป็นแอปพลิเคชันการเรียนภาษาและทางสังคมที่ทันสมัย และยังเป็นแพลตฟอร์มในการเชื่อมต่อกับพาร์ทเนอร์ทางภาษาที่มีคุณภาพจากทั่วโลก ทางเรามุ่งมั่นที่จะสร้างวงการสังคมภาษาที่มีชีวิตชีวาและการโต้ตอบ ทางเราไม่เพียงแค่มุมภาษา เราเสนอปาร์ตี้สังคมภาษาระดับโลก
                คุณต้องการหาพาร์ทเนอร์ทางภาษาระดับโลกเพื่อฝึกพูดด้วยหรือไม่? คุณต้องการเรียนรู้เกี่ยวกับลักษณะทางวัฒนธรรมของประเทศต่างๆหรือไม่? คุณต้องการฝึกออกเสียงภาษาต่างประเทศหรือไม่? คุณต้องการหาเพื่อนที่น่าเชื่อถือในต่างประเทศหรือไม่? คุณต้องการแสดงให้เห็นถึงด้านที่น่าสนใจที่สุดของวัฒนธรรมประเทศของคุณหรือไม่? คุณต้องการทำเพื่อนขณะท่องเที่ยวหรือไม่? 
                เราสามารถช่วยคุณได้ทุกอย่างนี้ ทำให้คุณได้พบกับพาร์ทเนอร์ทางภาษาระดับโลกและมีการสนทนาที่น่าตื่นเต้นจากความสะดวกสบายของบ้านคุณ
                TalkIn ทำให้ทุกอย่างเข้าถึงได้และเปลี่ยนโลกให้เป็นบ้านของคุณ!
                ●🎁ของขวัญทั่วโลก:
                TalkIn นำเสนอของขวัญทางวัฒนธรรมพิเศษจากมากกว่า 30 ประเทศ ช่วยให้คุณแบ่งปันวัฒนธรรมผ่านของขวัญและสร้างมิตรภาพข้ามพรมแดน!
                ●🆓การแปลฟรีตลอดชีพ:
                TalkIn ให้บริการแปลไม่จำกัดตลอดชีพทั่วทั้งแพลตฟอร์ม รับรองการสื่อสารที่ราบรื่นสำหรับผู้ใช้ทั่วโลก
                ●👬🏻อัลกอริทึมการจับคู่ที่มีประสิทธิภาพ:
                เทคโนโลยีการประมวลผลข้อมูลหลายมิติของเราสามารถจับคู่คุณกับพาร์ทเนอร์ทางภาษาที่เหมาะสมที่สุดทั่วโลกได้อย่างรวดเร็ว เพิ่มประสิทธิภาพการเรียนรู้และประสบการณ์ทางสังคม
                ●✍🏻โพสต์ทั่วโลก:
                ท่องเที่ยวรอบโลกจากบ้านของคุณเอง! สำรวจประเพณีและวัฒนธรรมของประเทศต่างๆ สัมผัสเสน่ห์ของวัฒนธรรมโลก เราสนับสนุนการแปลคำศัพท์ การแปลข้อความเต็ม และคุณสมบัติการโต้ตอบ เช่น การให้ของขวัญ การตอบกลับ และการกดไลค์
                ●🎁🫶🎁การเล่นเกมแลกเปลี่ยนของขวัญ:
                แลกเปลี่ยนของขวัญที่เฉพาะเจาะจงตามประเทศกับผู้ใช้จากทั่วโลก แบ่งปันลักษณะทางวัฒนธรรมของทั้งสองประเทศ ช่วยให้มิตรภาพข้ามพรมแดนได้ แสดงวัฒนธรรมของคุณต่อโลก
                ●🥰แชทด้วยอิโมจิแบบไดนามิก:
                อิโมจิแบบไดนามิกทำให้การสนทนาสนุกขึ้น เพิ่มเสน่ห์ให้กับการโต้ตอบของคุณ เพิ่มความสุขในการสื่อสารของคุณ
                ●#️⃣คุณสมบัติหัวข้อประเทศ:
                ไม่แน่ใจว่าจะพูดเรื่องอะไร? ไม่ต้องกังวล! เราให้บริการหัวข้อและภาพที่เกี่ยวข้องกับอาหาร สถาปัตยกรรม และวันหยุดจากมากกว่า 30 ประเทศ ทำให้คุณเริ่มการสนทนาได้อย่างง่ายดายและทำให้แต่ละการแชทมีชีวิตชีวา
                เข้าร่วม TalkIn และเริ่มต้นการเดินทางของคุณในการเรียนรู้ภาษาและการแลกเปลี่ยนวัฒนธรรมระดับโลก ทำให้การเรียนรู้และการสังสรรค์น่าตื่นเต้นยิ่งขึ้น!
              `,
              hi: `
                TalkIn में शामिल हों - भाषा सीखना और वैश्विक सामाजिक क्रांति!
                TalkIn एक युवा भाषा सीखने और सामाजिक एप्लीकेशन है, और दुनिया भर से उच्च-गुणवत्ता वाले भाषा साझेदारों के साथ जुड़ने का एक मंच भी है। हम एक जीवंत और इंटरैक्टिव भाषा सामाजिक सर्कल बनाने का लक्ष्य रखते हैं। हम सिर्फ भाषा कोने नहीं करते; हम जो प्रस्ताव करते हैं वह एक वैश्विक भाषा सामाजिक पार्टी है।
                क्या आप बोलने का अभ्यास करने के लिए वैश्विक भाषा साझेदारों को ढूंढना चाहते हैं? क्या आप विभिन्न देशों की सांस्कृतिक विशेषताओं के बारे में जानना चाहते हैं? क्या आप विदेशी भाषा के उच्चारण का अभ्यास करना चाहते हैं? क्या आप एक विश्वसनीय मित्र को विदेशी देश में ढूंढना चाहते हैं? क्या आ
                प अपने देश की संस्कृति के सबसे दिलचस्प पहलुओं को प्रदर्शित करना चाहते हैं? क्या आप यात्रा करते समय दोस्त बनाना चाहते हैं? हम आपको इन सभी में मदद कर सकते हैं, आपको दुनिया भर के भाषा साझेदारों से मिलने और अपने घर के आराम से रोमांचक बातचीत करने का मौका दे सकते हैं।
                TalkIn सब कुछ सुलभ बनाता है और दुनिया को आपके घर में बदल देता है!
                ●🎁वैश्विक उपहार:
                TalkIn 30 से अधिक विभिन्न देशों के विशेष सांस्कृतिक उपहार प्रदान करता है, जिससे आप उपहारों के माध्यम से संस्कृति साझा कर सकते हैं और सीमाओं को पार कर दोस्ती बना सकते हैं!
                ●🆓जीवन भर मुफ्त अनुवाद:
                TalkIn मंच भर में असीमित, जीवन भर के अनुवाद सेवाएं प्रदान करता है, जो दुनिया भर के उपयोगकर्ताओं के लिए निर्बाध संचार सुनिश्चित करता है।
                ●👬🏻कुशल मिलान एल्गोरिथ्म:
                हमारी बहुआयामी सूचना प्रसंस्करण प्रौद्योगिकी आपको दुनिया भर में अपने आदर्श भाषा साझेदार से जल्दी से मिला सकती है, जिससे सीखने की क्षमता और सामाजिक अनुभव में वृद्धि होती है।
                ●✍🏻वैश्विक पोस्ट:
                अपने घर छोड़े बिना दुनिया भर की आभासी यात्रा करें! विभिन्न देशों की रीति-रिवाजों और संस्कृतियों का अन्वेषण करें, वैश्विक संस्कृति का आकर्षण अनुभव करें। हम शब्द अनुवाद, पूर्ण-पाठ अनुवाद, और उपहार देने, उत्तर देने, और पसंद करने जैसी इंटरैक्टिव सुविधाएं प्रदान करते हैं।
                ●🎁🫶🎁उपहार विनिमय गेमप्ले:
                दुनिया भर के उपयोगकर्ताओं के साथ देश-विशिष्ट उपहारों का आदान-प्रदान करें, दोनों देशों की सांस्कृतिक विशेषताओं को साझा करें और सीमाओं को पार करने वाली दोस्ती को सक्षम बनाएं। अपनी संस्कृति को दुनिया के सामने प्रदर्शित करें।
                ●🥰गतिशील इमोजी के साथ चैट करें:
                गतिशील इमोजी बातचीत को और अधिक मजेदार बनाते हैं, आपकी बातचीत में जादू का स्पर्श जोड़ते हैं। अपने संचार में खुशी जोड़ें।
                ●#️⃣देश विषय सुविधा:
                बात करने के लिए क्या है, इस बारे में निश्चित नहीं हैं? कोई चिंता नहीं! हम 30 से अधिक देशों से संबंधित खाद्य, वास्तुकला, और छुट्टियों के विषयों और छवियों को प्रदान करते हैं, जो आपको बातचीत शुरू करने और प्रत्येक चैट को जीवंत बनाने में मदद करते हैं।
                TalkIn में शामिल हों और वैश्विक भाषा सीखने और सांस्कृतिक आदान-प्रदान की अपनी यात्रा पर निकलें, सीखने और सामाजिकीकरण को और भी रोमांचक बनाएं!
              `,
              pl: `
                Dołącz do TalkIn - Nauka Języka i Globalna Rewolucja Społeczna!
                TalkIn to młodzieżowa aplikacja do nauki języków i spotkań społecznych, a także platforma do łączenia się z wysokiej jakości partnerami językowymi z całego świata. Naszym celem jest stworzenie dynamicznego i interaktywnego kręgu językowego. Nie ograniczamy się do kącików językowych; oferujemy globalną imprezę językową.
                Chcesz znaleźć globalnych partnerów do ćwiczenia mówienia? Chcesz poznać kulturowe cechy różnych krajów? Chcesz ćwiczyć wymowę w obcym języku? Chcesz znaleźć godnego zaufania przyjaciela w obcym kraju? Chcesz pokazać najciekawsze aspekty kultury swojego kraju? Chcesz nawiązywać przyjaźnie w trakcie podróży? 
                Możemy pomóc Ci we wszystkim, umożliwiając spotkania z globalnymi partnerami językowymi i emocjonujące rozmowy w zaciszu domowym.
                TalkIn sprawia, że wszystko staje się dostępne i zamienia świat w Twój dom!
                ●🎁Globalne Prezenty:
                TalkIn oferuje ekskluzywne kulturowe upominki z ponad 30 różnych krajów, umożliwiając dzielenie się kulturą poprzez prezenty i budowanie przyjaźni ponad granicami!
                ●🆓Bezpłatne Tłumaczenie Dożywotnie:
                TalkIn zapewnia nieograniczone, dożywotnie usługi tłumaczeniowe na całej platformie, zapewniając płynną komunikację użytkownikom na całym świecie.
                ●👬🏻Efektywny Algorytm Doboru:
                Nasza wielowymiarowa technologia przetwarzania informacji szybko dopasowuje Cię do idealnego partnera językowego na całym świecie, zwiększając efektywność nauki i doświadczenie społeczne.
                ●✍🏻Globalne Posty:
                Wybierz się na wirtualną wycieczkę dookoła świata nie wychodząc z domu! Poznaj zwyczaje i kultury różnych krajów, doświadczając uroku globalnej kultury. Wspieramy tłumaczenie słów, pełnotekstowe tłumaczenie oraz funkcje interaktywne, takie jak wysyłanie prezentów, odpowiedzi i polubienia.
                ●🎁🫶🎁Gra w Wymianę Prezentów:
                Wymieniaj się prezentami charakterystycznymi dla poszczególnych krajów z użytkownikami z całego świata, dzieląc się cechami kulturowymi obu krajów i pozwalając przyjaźniom przekraczać granice. Pokaż światu swoją kulturę.
                ●🥰Czat z Dynamicznymi Emotikonami:
                Dynamiczne emotikony sprawiają, że rozmowy są bardziej zabawne, dodając odrobinę magii do Twoich interakcji. Dodaj radość do komunikacji.
                ●#️⃣Funkcja Tematów Krajowych:
                Nie wiesz, o czym rozmawiać? Nie martw się! Oferujemy tematy i obrazy związane z jedzeniem, architekturą i świętami z ponad 30 krajów, ułatwiając rozpoczęcie rozmowy i ożywienie każdej czatowej dyskusji.
                Dołącz do TalkIn i rozpocznij swoją podróż z globalną nauką języków i wymianą kulturową, sprawiając, że nauka i towarzyskość stają się jeszcze bardziej ekscytujące!
              `,
              nl: `
                Doe mee met TalkIn - Taal leren en wereldwijde sociale revolutie!
                TalkIn is een jeugdige taalleer- en sociale applicatie, en ook een platform om in contact te komen met hoogwaardige taalpartners van over de hele wereld. We streven ernaar een levendige en interactieve taalsociale kring te creëren. Wij doen niet aan taalhoekjes; wat wij bieden is een wereldwijd taalsociaal feest.
                Wil je wereldwijde taalpartners vinden om mee te oefenen? Wil je de culturele kenmerken van verschillende landen leren kennen? Wil je buitenlandse taaluitspraak oefenen? Wil je een betrouwbare vriend vinden in een vreemd land? Wil je de meest interessante aspecten van de cultuur van je eigen land laten zien? Wil je vrienden maken tijdens het reizen? 
                Wij kunnen je hierbij helpen, zodat je wereldwijde taalpartners kunt ontmoeten en spannende gesprekken kunt voeren vanuit het comfort van je eigen huis.
                TalkIn maakt alles toegankelijk en verandert de wereld in jouw thuis!
                ●🎁Globale Geschenken:
                TalkIn biedt exclusieve culturele geschenken uit meer dan 30 verschillende landen, waardoor je cultuur kunt delen via geschenken en vriendschappen over grenzen heen kunt opbouwen!
                ●🆓Gratis Levenslange Vertaling:
                TalkIn biedt onbeperkte, levenslange vertaaldiensten over het hele platform, om naadloze communicatie voor gebruikers over de hele wereld te garanderen.
                ●👬🏻Efficiënte Matchingsalgoritme:
                Onze multidimensionale informatie verwerkingstechnologie kan je snel matchen met je ideale taalpartner wereldwijd, wat de leer-efficiëntie en sociale ervaring verbetert.
                ●✍🏻Wereldwijde Berichten:
                Maak een virtuele reis rond de wereld zonder je huis te verlaten! Verken de gebruiken en culturen van verschillende landen en ervaar de charme van de wereldcultuur. Wij ondersteunen woordvertaling, volledige tekstvertaling en interactieve functies zoals cadeaus geven, antwoorden en likes.
                ●🎁🫶🎁Geschenkenruil Gameplay:
                Wissel land-specifieke geschenken uit met gebruikers van over de hele wereld, deel de culturele kenmerken van beide landen en laat vriendschappen grenzen overschrijden. Toon de wereld jouw cultuur.
                ●🥰Chatten met Dynamische Emoji's:
                Dynamische emoji's maken gesprekken leuker en voegen een vleugje magie toe aan je interacties. Voeg vreugde toe aan je communicatie.
                ●#️⃣Land Topics Functie:
                Niet zeker waarover te praten? Geen zorgen! Wij bieden onderwerpen en afbeeldingen gerelateerd aan eten, architectuur en feestdagen uit meer dan 30 landen, waardoor het gemakkelijk is om een gesprek te beginnen en elke chat
                te verlevendigen.Doe mee met TalkIn en begin aan je reis van wereldwijde taalstudie en culturele uitwisseling, waardoor leren en socialiseren nog spannender worden!
              `,
              sv: `
                Gå med i TalkIn - Språkinlärning och Global Social Revolution!
                TalkIn är en ungdomlig språkinlärnings- och social applikation, och också en plattform för att ansluta med högkvalitativa språkpartners från hela världen. Vi strävar efter att skapa en livlig och interaktiv språklig social cirkel. Vi gör inte språkhörnor; det vi erbjuder är ett globalt språkligt socialt party.
                Vill du hitta globala språkpartners för att öva på att tala? Vill du lära dig om olika länders kulturella egenskaper? Vill du öva på uttal i främmande språk? Vill du hitta en pålitlig vän i ett främmande land? Vill du visa upp de mest intressanta aspekterna av din egen kulturs kultur? Vill du skaffa vänner under resor? 
                Vi kan hjälpa dig med allt detta, vilket gör att du kan träffa globala språkpartners och ha spännande samtal från ditt eget hem.
                TalkIn gör allt tillgängligt och förvandlar världen till ditt hem!
                ●🎁Globala Gåvor:
                TalkIn erbjuder exklusiva kulturella gåvor från över 30 olika länder, vilket gör att du kan dela kultur genom gåvor och bygga vänskap över gränserna!
                ●🆓Gratis Livstidsöversättning:
                TalkIn tillhandahåller obegränsad livstidsöversättningstjänster över hela plattformen och säkerställer sömlös kommunikation för användare runt om i världen.
                ●👬🏻Effektiv Matchningsalgoritm:
                Vår multidimensionella informationsteknik kan snabbt matcha dig med din ideala språkpartner världen över, vilket ökar inlärningseffektiviteten och sociala upplevelsen.
                ●✍🏻Globala Inlägg:
                Ta en virtuell resa runt världen utan att lämna ditt hem! Utforska sedvänjor och kulturer i olika länder, upplev den globala kulturens charm. Vi stöder ordöversättning, heltextöversättning och interaktiva funktioner som gåvogivning, svar och gillanden.
                ●🎁🫶🎁Gåvobytes Gameplay:
                Byt landspecifika gåvor med användare från hela världen, dela de kulturella egenskaperna hos båda länderna och låt vänskapen överskrida gränserna. Visa världen din kultur.
                ●🥰Chatta med Dynamiska Emojis:
                Dynamiska emojis gör konversationer roligare och lägger till en touch av magi i dina interaktioner. Lägg till glädje i din kommunikation.
                ●#️⃣Landsämnen Funktion:
                Inte säker på vad du ska prata om? Inga problem! Vi tillhandahåller ämnen och bilder relaterade till mat, arkitektur och helgdagar från över 30 länder, vilket gör det enkelt för dig att starta en konversation och liva upp varje chatt.
                Gå med i TalkIn och påbörja din resa med global språkinlärning och kulturellt utbyte, vilket gör lärandet och socialiseringen ännu mer spännande!
              `
            }
          }
        }

        getLangTxt (key) {
          const item = this.jsonData[key]
          if (item) {
            const list = item[that.$lang].split('\n')
            list.splice(0, 1)
            list.forEach(it => {
              it = it.replaceAll('\n                    ', '')
            })
            return list
          } else {
            return ''
          }
        }
      }
      const tranTxt = new Trantxtlang(this.$lang)
      // 函数获取翻译
      function getTranFromKey (key) {
        return tranTxt.getLangTxt(key)
      }
      this.setDom(getTranFromKey('store-tran'))
    //   str.split('##############')[0].replaceAll('\n                    ', '')
    },
    setDom (list) {
      const sl = list[0].split(' - ')
      const obj = {
        slogan: `
                <p>${sl[0]}</p>
                <p ${sl[1].length > 47 ? 'class="s-p2"' : ''}>${sl[1]}</p>
        `,
        desc: `
                <p>${list[1]}</p>
                <p>${list[2]}</p>
                <p>${list[3]}</p>
                <p>${list[4]}</p>
        `,
        exhibit: `
                <ul class="e-list">
                    <li class="e-row">
                        <h3>${list[5]} </h3>
                        <p>${list[6]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[7]} </h3>
                        <p>${list[8]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[9]} </h3>
                        <p>${list[10]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[11]} </h3>
                        <p>${list[12]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[13]} </h3>
                        <p>${list[14]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[15]} </h3>
                        <p>${list[16]}</p>
                    </li>
                    <li class="e-row">
                        <h3>${list[17]} </h3>
                        <p>${list[18]}</p>
                    </li>
                    <li class="e-row">
                        <p>${list[19]}</p>
                    </li>
                </ul>
        `
      }
      this.tranDom = obj
    }
  }
}
</script>
<style lang="scss">
.store-view{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  overflow-y: auto;
  overflow-x: hidden;
  background: #F1F3FF;
  .s-contain{
    padding-top: .6rem;
    width: 9.42rem;
    margin: 0 auto;
    .s-slogan{
        padding-top: .9rem;

        color: #27272E;
        font-family: "SF Pro Text";
        font-size: .42rem;
        font-style: normal;
        font-weight: 800;
        line-height: .52rem; /* 123.81% */
        letter-spacing: -.0168rem;
        text-transform: capitalize;
        white-space: nowrap;

        text-align: left;

        .s-p2{
            white-space: normal;
        }
    }
    .s-sign{
        padding: .45rem 0;
        .img{
            width: 100%;
        }
    }
    .s-desc{
        color: #585965;
        font-family: "SF Pro Text";
        font-size: .17rem;
        font-style: normal;
        font-weight: 500;
        line-height: .3rem;
        text-align: left;
        p:nth-child(2){
            padding: .45rem 0;
        }
    }
    .s-exhibit{
        display: flex;
        justify-content: space-between;
        padding: .45rem 0;
        img{
            vertical-align: bottom;
            width: 1.38rem;
        }
    }
    .exhibit-desc{
        .e-list{
            color: #585965;
            font-family: "SF Pro Text";
            font-size: .17rem;
            font-style: normal;
            font-weight: 500;
            line-height: .3rem;
            text-align: left;
            .e-row{
                padding-bottom: .3rem;
                h3{
                    font-weight: 700;
                }
                &:last-child{
                    padding-bottom: 0;
                }
            }
        }
    }
    .s-ticket{
        padding: .66rem 0 2.34rem 0;
        .ticket-card{
            width: 100%;
            max-width: 3.9rem;
            text-align: center;
            margin: 0 auto;
            .t-tit{
                font-family: SF Pro Text;
                font-size: .2rem;
                font-weight: 900;
                line-height: .24rem;
                letter-spacing: 0px;
                text-align: center;
                padding-bottom: .1rem;
            }
            .t-desc{
                .t-bg{
                    width: 100%;
                    vertical-align: bottom;
                }
                .t-time{
                    background-repeat: no-repeat;
                    background-size: 98% 98%;
                    background-position: 50% 50%;
                    background-image: linear-gradient(92deg, #5AFFF5 0.39%, #B6FF5A 23.32%, #FFEE5A 43.31%, #FFA800 77.31%, #EE0EF2 101.16%);;
                    color: #fff;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .t-btn{
            color: #27272E;
            font-family: "SF Pro Text";
            font-size: .20rem;
            font-style: normal;
            font-weight: 800;
            line-height: .48rem;
            letter-spacing: -.01rem;
            text-transform: capitalize;
            height: 1.16rem;
            position: relative;
            .btn-img{
            width: 2.10rem;
            position: relative;
            .img{
                width: 100%;
            }
            &:last-child{
                position: absolute;
                top: 0;
                left: 1.70rem;
                width: 2.32rem;
            }
            }
            .btn1{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.60rem;
            height: .55rem;
            transform: translate(-50%, -50%);
            // background: rgba(255,255,255,.3);
            margin-top: -4%;
            z-index: 10;
            cursor:pointer;
            }
            .btn2{
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.74rem;
            height: .55rem;
            transform: translate(-50%, -50%);
            // background: rgba(255, 255, 255, 0.3);
            margin-top: -4%;
            z-index: 10;
            cursor:pointer;
            }
        }
    }
  }
}
</style>
