<template>
      <div :style="cHeight && ('height:'+ cHeight +'px')" class="t-page page1" :class="{'is-mobile': isMobile}">
        <div class="t-contain">
          <div class="t-left">
            <div class="t-txt">
              <span>{{getTranFromKey('conversing_with_native').split('&')[0]}}</span>
              <span class="c_FFEE5A" v-if="getTranFromKey('conversing_with_native').split('&').length === 2">{{getTranFromKey('conversing_with_native').split('&')[1]}}</span>
              <!--<h2>{{getTranFromKey('slogan').split(',')[0]}},</h2>
              <h2>{{getTranFromKey('slogan').split(',')[1]}}</h2> -->
            </div>
            <div class="t-desc">
                {{getTranFromKey('practice_language')}}
            </div>
            <div class="t-btns">

              <!--<div class="btn-img" @click="$TurnIosOrAndroid(1)">
                <img draggable="false" class="img" src="@/assets/images/download-app.png" alt="talkin android download btn" />
                <div class="btn1"></div>
              </div>
              <div class="btn-img" @click="$TurnIosOrAndroid(0)">
                <img draggable="false" class="img" src="@/assets/images/download-google.png" alt="talkin ios download btn" />
                <div class="btn2"></div>
              </div>-->
                <div class="btn-code">
                    <img draggable="false" class="img" src="@/assets/images/action2/code.png" alt="talkin download code" />
                    <span class="txt">{{getTranFromKey('scan_download')}}</span>
                </div>
            </div>
          </div>
          <div class="t-right">
            <PhoneAction />
          </div>
        </div>
      </div>
</template>

<script>
import PhoneAction from './PhoneAction.vue'
export default {
  name: 'HomeFirstPage',
  props: {
    cHeight: [String, Number],
    isMobile: Boolean
  },
  components: {
    PhoneAction
  }
}
</script>

<style lang="scss" scoped>

.page1{
  display: flex;
  align-items: center;
  justify-content: center;

//   border-bottom: .02rem solid #F1F3FF;
  box-sizing: border-box;
    border-bottom: .02rem solid #37394B;

//   background: #F1F3FF;

  background: #111320;

  &.is-mobile{
    .t-contain .t-left .t-txt{
        font-size: .34rem;
        line-height: .4rem;
    }
    .t-contain .t-left .t-desc{
        display: none;
    }
  }
  .t-contain{
    height: 35.4%;
    // background: red;
    // width: 7.72rem;

    display: flex;
    align-items: center;
    .t-left{
        width: 5.04rem;
      // width: 354px;
    //   width: 3.80rem;
      text-align: left;
        color: #F1F3FE;
    padding-right: .1rem;
    box-sizing: border-box;

      .t-txt{
        // color: #27272E;
        // font-family: "SF Pro Text";
        // font-size: .44rem;
        // font-style: normal;
        // font-weight: 800;
        // line-height: .48rem;
        // letter-spacing: -.01rem;
        // text-transform: capitalize;
        // margin-bottom: .2rem;
        // padding-left: .2rem;
        padding-bottom: .21rem;
        // color: #F1F3FE;
        font-family: "SF Pro Text";
        font-size: .51rem;
        font-style: normal;
        font-weight: 900;
        line-height: .66rem;
        margin-bottom: 0;
        padding-left: 0;
        .c_FFEE5A{
            color:  #FFEE5A;
        }
      }
      .t-desc{
        // color: #F1F3FE;
        font-family: "SF Pro Text";
        font-size: .24rem;
        font-style: normal;
        font-weight: 500;
        line-height: .36rem;
        padding-bottom: .4125rem;
      }
      .t-btns{
          display: inline-block;
        .btn-code{
            height: .825rem;
            border-radius: 10px;
            border: 1px solid #37394B;
            background: #111320;
            padding: .06rem;
            display: flex;
            align-items: center;
            .img{
                height: 100%;
            }
            .txt{
                padding: 0 .21rem;
                color: #F1F3FF;
                font-family: "SF Pro Text";
                font-size: .15rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
      }
    //   .t-btns{
    //     color: #27272E;
    //     font-family: "SF Pro Text";
    //     font-size: .20rem;
    //     font-style: normal;
    //     font-weight: 800;
    //     line-height: .48rem;
    //     letter-spacing: -.01rem;
    //     text-transform: capitalize;
    //     height: 1.16rem;
    //       position: relative;
    //     .btn-img{
    //       width: 2.10rem;
    //       position: relative;
    //       .img{
    //         width: 100%;
    //       }
    //       &:last-child{
    //         position: absolute;
    //         top: 0;
    //         left: 1.70rem;
    //         width: 2.32rem;
    //       }
    //     }
    //     .btn1{
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       width: 1.60rem;
    //       height: .55rem;
    //       transform: translate(-50%, -50%);
    //       // background: rgba(255,255,255,.3);
    //       margin-top: -4%;
    //       z-index: 10;
    //       cursor:pointer;
    //     }
    //     .btn2{
    //       position: absolute;
    //       top: 50%;
    //       left: 50%;
    //       width: 1.74rem;
    //       height: .55rem;
    //       transform: translate(-50%, -50%);
    //       // background: rgba(255, 255, 255, 0.3);
    //       margin-top: -4%;
    //       z-index: 10;
    //       cursor:pointer;
    //     }
    //   }
    }
  }
}
</style>
