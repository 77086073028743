import { render, staticRenderFns } from "./FixNav.vue?vue&type=template&id=727491bc&scoped=true&"
import script from "./FixNav.vue?vue&type=script&lang=js&"
export * from "./FixNav.vue?vue&type=script&lang=js&"
import style0 from "./FixNav.vue?vue&type=style&index=0&id=727491bc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "727491bc",
  null
  
)

export default component.exports