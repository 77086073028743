var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"colors-page"},[_c('div',{staticClass:"page-green isShow"},[_vm._m(0),_c('div',{staticClass:"p-right"},[_c('h2',{staticClass:"p-title"},[_vm._v(_vm._s(_vm.getTranFromKey('free_trans')))]),_c('h3',{staticClass:"p-desc"},[_vm._v(_vm._s(_vm.getTranFromKey('free_trans_text')))])])]),_c('div',{staticClass:"page-purple isShow"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"p-left"},[_c('h2',{staticClass:"p-title"},[_vm._v(_vm._s(_vm.getTranFromKey('global_gift')))]),_c('h3',{staticClass:"p-desc"},[_vm._v(_vm._s(_vm.getTranFromKey('global_gift_text')))])]),_vm._m(3)]),_c('div',{staticClass:"page-yellow isShow"},[_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"p-right"},[_c('h2',{staticClass:"p-title"},[_vm._v(_vm._s(_vm.getTranFromKey('global_post')))]),_c('h3',{staticClass:"p-desc"},[_vm._v(_vm._s(_vm.getTranFromKey('global_post_text')))])])]),_c('div',{staticClass:"page-pink isShow"},[_vm._m(7),_vm._m(8),_c('div',{staticClass:"p-left"},[_c('h2',{staticClass:"p-title"},[_vm._v(_vm._s(_vm.getTranFromKey('Learn_lang_video')))]),_c('h3',{staticClass:"p-desc"},[_vm._v(_vm._s(_vm.getTranFromKey('Learn_lang_video_text')))])]),_vm._m(9)]),_c('div',{staticClass:"page-white isShow"},[_c('div',{staticClass:"page-black"},[_c('slogan-card')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-left"},[_c('img',{staticClass:"img-hands",attrs:{"draggable":"false","src":require("@/assets/images/colorful/Clapping hands.png")}}),_c('img',{staticClass:"img-g4",attrs:{"draggable":"false","src":require("@/assets/images/colorful/g4.png")}}),_c('img',{staticClass:"img-g3",attrs:{"draggable":"false","src":require("@/assets/images/colorful/g3.png")}}),_c('img',{staticClass:"img-g2",attrs:{"draggable":"false","src":require("@/assets/images/colorful/g2.png")}}),_c('img',{staticClass:"img-g1",attrs:{"draggable":"false","src":require("@/assets/images/colorful/g1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-bg"},[_c('img',{staticClass:"img",attrs:{"draggable":"false","src":require("@/assets/images/colorful/p_bg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-face"},[_c('span',{staticClass:"point"}),_c('span',{staticClass:"block"}),_c('span',{staticClass:"point"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-right"},[_c('div',{staticClass:"img-bg"}),_c('img',{staticClass:"img2",attrs:{"draggable":"false","src":require("@/assets/images/colorful/p2.png")}}),_c('img',{staticClass:"img3",attrs:{"draggable":"false","src":require("@/assets/images/colorful/p3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-bg"},[_c('img',{staticClass:"img",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y_bg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-face"},[_c('span',{staticClass:"point"}),_c('span',{staticClass:"block"}),_c('span',{staticClass:"point"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-left"},[_c('img',{staticClass:"img2",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y2.png")}}),_c('img',{staticClass:"img3",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y3.png")}}),_c('img',{staticClass:"img4",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y4.png")}}),_c('img',{staticClass:"img5",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y5.png")}}),_c('img',{staticClass:"img6",attrs:{"draggable":"false","src":require("@/assets/images/colorful/y6.png")}}),_c('img',{staticClass:"img7",attrs:{"draggable":"false","src":require("@/assets/images/colorful/h1.png")}}),_c('img',{staticClass:"img8",attrs:{"draggable":"false","src":require("@/assets/images/colorful/h2.png")}}),_c('img',{staticClass:"img9",attrs:{"draggable":"false","src":require("@/assets/images/colorful/h3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-bg"},[_c('img',{staticClass:"img",attrs:{"draggable":"false","src":require("@/assets/images/colorful/pk_bg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-face"},[_c('span',{staticClass:"point"}),_c('span',{staticClass:"block"}),_c('span',{staticClass:"point"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-right"},[_c('img',{staticClass:"img-bg",attrs:{"draggable":"false","src":require("@/assets/images/colorful/pk_bg2.png")}}),_c('img',{staticClass:"img2",attrs:{"draggable":"false","src":require("@/assets/images/colorful/pk2.png")}}),_c('img',{staticClass:"img3",attrs:{"draggable":"false","src":require("@/assets/images/colorful/pk3.png")}}),_c('img',{staticClass:"img4",attrs:{"draggable":"false","src":require("@/assets/images/colorful/pk4.png")}})])
}]

export { render, staticRenderFns }